import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Top from './top.js';

import { DataContext } from '../firebase/datacontext.js';

const Chat_contact = () => {

    //
    var u = window.location.pathname;
    var l = u.substr(14);
    //console.log(u);
    //console.log(l);

    //
    const [ready, setReady] = useState(false);
    const [msg, setMsg] = useState('');
    
    //
    var chatAuth = 'chat_auth';
    var chatNoauth ='chat_noauth';

    //
    const { saveMsgContact, getMsgContact, saveMsgView, msgs, activeuser } = useContext(DataContext);

    //TEXTS IDIOMS
    var txt1 = "MESSAGES CENTER";
    var txt2 = "With: ";
    var txt3 = "Loading...";
    var txt4 = "SEND";
    var txt5 = "Write a message";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "CENTRO DE MENSAJES";
            txt2 = "Con: ";
            txt3 = "Cargando...";
            txt4 = "ENVIAR";
            txt5 = "Escribe un mensaje";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();

    //
    async function postMsg(){
        var x = document.getElementById('chat_txt');
        if(x.value != ''){
            await setView();
            await saveMsgContact(l, {
                text: msg,
                author: activeuser.key_id,
                name: activeuser.name + " " + activeuser.lastname,
                view: 'no'
            });
            x.value = '';

        }else{
            alert(txt5);
        }  
    }

    async function setView(){
        if(msgs.messages){
            for (var i = 0; i < msgs.messages.length ; i++) {
                if(msgs.messages[i].author != activeuser.key_id){
                    msgs.messages[i].view = "yes";
                    await saveMsgView(l, msgs);
                }else{
                    //console.log(msgs);
                }
            }
        }else{
            //console.log("Sin mensajes");
        }
    }

    //
    useEffect(async () => {
        await getMsgContact(l);
        setReady(true)
    },[ready]);


    return(

        <>
           <Top/>
           <div className="Main">
                <div className="chat_window">
                    <div className="chat_title">
                        <h1>{txt1}</h1>
                        <div className="chat_info">
                            {
                                activeuser.key_id == msgs.creator
                                ?
                                <Link className="textlink" to={`/${ msgs.receptor_profile }/${ msgs.receptor }`}>{txt2} <strong>{msgs.receptor_name}</strong></Link>
                                :
                                <Link className="textlink" to={`/${ msgs.creator_profile }/${ msgs.creator }`}>{txt2} <strong>{msgs.creator_name}</strong></Link>
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="chat_area">
                        <div className="chat_box">
                            {
                                msgs.length== 0
                                ?
                                <p>{txt3}</p>
                                :
                                 msgs.messages.map((item, index) => (
                                    <div key={index}>
                                        <div className={ activeuser.key_id == item.author ? chatAuth : chatNoauth }>
                                            <p className="chat_m">{item.text}</p>    
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                ))
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="chat_msg">
                            <input className="chat_txt" id="chat_txt"
                                type="text"
                                placeholder={txt5}
                                onChange={e => setMsg(e.target.value)}
                            />
                            <button className="chat_btn" onClick={postMsg}>{txt4}</button>
                            <div className="clear"></div>
                        </div>
                </div>
            </div>
        </>

    );

}

export default Chat_contact;