import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const OffersList = () => {

    //
    const { activeuser, getOffersPlayer,  getOffersAgent, alloffers, premium, premiumsub, buySub, quitOffer } = useContext(DataContext);

    const [mainlist, setMainlist] = useState([]);
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "PROPOSALS";
    var txt2 = "Search by offer";
    var txt3 = "NO RESULTS";
    var txt4 = "Public offer";
    var txt5 = "From: ";
    var txt6 = "To: ";
    var txt7 = "MESSAGE";
    var txt8 = "DELETE";
    var txt9 = "further proposals";
    var txt10 = "BECOME A PREMIUM USER TO SEE ALL!"

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "PROPUESTAS";
            txt2 = "Busca por oferta";
            txt3 = "SIN RESULTADOS";
            txt4 = "Oferta pública";
            txt5 = "De: ";
            txt6 = "Para: ";
            txt7 = "MENSAJE";
            txt8 = "ELIMINAR";
            txt9 = "propuestas más";
            txt10 = "¡COVIÉRTETE EN USUARIO PREMIUM PARA VER TODAS!"
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(() => {
        fillOffers();
    },[ready]);

    //
    async function fillOffers(){
        if(activeuser.profile == "Player"){
            await getOffersPlayer();
            setList(alloffers);
            setMainlist(alloffers);
            setReady(true);
        }
        else{
            await getOffersAgent();
            setList(alloffers);
            setMainlist(alloffers);
            setReady(true);

        }
    }

    //SEARCH ENGINE
    function searching(){
        var list2 = mainlist;
        var a = document.getElementById("search_field").value.toLowerCase();
        var x = list2.filter(z => {
            return z.offer.toLowerCase().includes(a);
        });
        //console.log(x);
        setList(x)
        //console.log(list);
    }

    //
    const navigate = useNavigate();

    function goChat(x){
        navigate(`/Chat_offers/${ x }`);
    }

    function goProposals(x){
        navigate(`/Proposals/${ x }`);
    }

    return(
        <>
        <Top/>
        <div className="main">
            <div className="list_box">
                <h1>{txt1}</h1>

                {

                    ((premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')) || activeuser.profile == "Agent"
                    ?
                    <div>
                        <div className="search_box">
                            <input
                                type="text"
                                id="search_field"
                                name="search_field"
                                placeholder={txt2}
                                onChange={searching}
                            />
                        </div>
                        {
                            list.length == 0
                            ?
                            <div className="loading_msg">{txt3}</div>
                            :
                            list.reverse().map((item, index) => (
                                <div className="list_item2"  key={item.chatid}>
                                    <div className="list_data">
                                        <p className="list_name">{item.offer}</p>
                                        {
                                             item.receptor == "open"
                                             ?
                                             <p>{txt4}</p>
                                             :
                                             <Link to={ activeuser.key_id == item.receptor ? `/${ item.creator_profile }/${ item.creator }` : `/${ item.receptor_profile }/${ item.receptor }`} className="textlink3">{activeuser.key_id == item.receptor ? txt5 + item.creator_name : txt6 + item.receptor_name}</Link>
                                        }
                                        <div>
                                            {
                                                item.receptor == "open"
                                                ?
                                                <button className="btn_min" onClick={() => goProposals(item.chatid)}>{txt7}</button>
                                                :
                                                <button className="btn_min" onClick={() => goChat(item.chatid)}>{txt7}</button>
                                            }
                                           
                                            {
                                                activeuser.profile != "Player"
                                                ?
                                                <button className="btn_cancel_min" onClick={() => quitOffer(item.chatid)}>{txt8}</button>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div>
                        {
                            list.slice(0, 1).map((item, index) => (
                                <div className="list_item2"  key={item.chatid}>
                                    <div className="list_data">
                                        <p className="list_name">{item.offer}</p>
                                        <Link to={`/${ item.creator_profile }/${ item.creator }`} className="textlink3">{txt5 + item.creator_name}</Link>
                                        <button className="btn_min" onClick={() => goChat(item.chatid)}>{txt7}</button>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            ))
                        }
                        <div className="bottom_btn_set">
                            <p><strong>{list.length == 0 ? "0" : list.length - 1}</strong>{" " + txt9}</p>
                            <button className="btn_min_gold" onClick={buySub}>{txt10}</button>
                        </div>
                    </div>
                }

                
            


            </div>

        </div>
        </>

    );


}

export default OffersList;