import React from 'react';
import {Routes, Route } from 'react-router-dom';

import { DataProvider } from './firebase/datacontext.js';
import './elementos/styles.css';

import Intro from './elementos/intro.js';
import Login from './elementos/login.js';
import LoginReset from './elementos/login_reset.js';
import Register from './elementos/register.js';
import Profile from './elementos/profile.js';
import ProfileAgent from './elementos/profile_agent.js';
import ProfileCoach from './elementos/profile_coach.js';
import ProfileClub from './elementos/profile_club.js';
import User from './elementos/user.js';
import Wall from './elementos/wall.js';
import Players from './elementos/players.js';
import Roster from './elementos/roster.js';
import AgentRoster from './elementos/agentroster.js';
import Player from './elementos/player.js';
import Agents from './elementos/agents.js';
import Agent from './elementos/agent.js';
import Coaches from './elementos/coaches.js';
import Coach from './elementos/coach.js';
import Clubs from './elementos/clubs.js';
import Club from './elementos/club.js';
import Chat_offers from './elementos/chat_offers.js';
import Chat_contact from './elementos/chat_contact.js';
import Proposals from './elementos/proposals.js';
import OpenOffer from './elementos/open_offer.js';
import Offers from './elementos/offers.js';
import OffersList from './elementos/offerslist.js';
import Event from './elementos/event.js';
import Events from './elementos/events.js';
import Messages from './elementos/messages.js';
import Following from './elementos/following.js';
import Followers from './elementos/followers.js';
import WhatsApp from './elementos/msgwhats.js';
import Privacy from './elementos/privacy.js';
import Error404 from './elementos/error404.js';

import Footer from './elementos/footer.js';

function App() {
  return (
    
    <DataProvider>
        <WhatsApp/>
        <Routes>
          <Route path="/" element={<Intro/>}/>
          <Route path="/Login" element={<Login/>}/>
          <Route path="Register/" element={<Register/>}/>
          <Route path="LoginReset/" element={<LoginReset/>}/>
          <Route path="Profile/" element={<Profile/>}/>
          <Route path="ProfileAgent/" element={<ProfileAgent/>}/>
          <Route path="ProfileClub/" element={<ProfileClub/>}/>
          <Route path="ProfileCoach/" element={<ProfileCoach/>}/>
          <Route path="User/" element={<User/>}/>
          <Route path="Wall/" element={<Wall/>}/>
          <Route path="Roster/" element={<Roster/>}/>
          <Route path="AgentRoster/" element={<AgentRoster/>}/>
          <Route path="Players/" element={<Players/>}/>
          <Route path="Player/:id" element={<Player/>}/>
          <Route path="Agents/" element={<Agents/>}/>
          <Route path="Agent/:id" element={<Agent/>}/>
          <Route path="Coaches/" element={<Coaches/>}/>
          <Route path="Coach/:id" element={<Coach/>}/>
          <Route path="Clubs/" element={<Clubs/>}/>
          <Route path="Club/:id" element={<Club/>}/>
          <Route path="Chat_offers/:id" element={<Chat_offers/>}/>
          <Route path="Chat_contact/:id" element={<Chat_contact/>}/>
          <Route path="Proposals/:id" element={<Proposals/>}/>
          <Route path="OpenOffer/" element={<OpenOffer/>}/>
          <Route path="Offers/" element={<Offers/>}/>
          <Route path="OffersList/" element={<OffersList/>}/>
          <Route path="Event/" element={<Event/>}/>
          <Route path="Events/" element={<Events/>}/>
          <Route path="Messages/" element={<Messages/>}/>
          <Route path="Following/" element={<Following/>}/>
          <Route path="Followers/" element={<Followers/>}/>
          <Route path="Privacy/" element={<Privacy/>}/>
          <Route path='*' element={<Error404 />}/>
        </Routes>
        <Footer/>
    </DataProvider>

    
  );
}

export default App;
