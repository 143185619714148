import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';

import Top from './top.js';
import Countries from './countries.json';

import { DataContext } from '../firebase/datacontext.js';

const Profile = () => {
  
  //
  const { activeuser, savePlayer, setReloader, reloader } = useContext(DataContext);

  //
  const [h, setH] = useState('0');
  const [w, setW] = useState('0');
  
  //TEXTS IDIOMS
  var txt1 = "Edit profile";
  var txt2 = "FIRSTNAME(S):";
  var txt3 = "SURNAME(S):";
  var txt4 = "BIO:";
  var txt5 = "Maximum 100 characters";
  var txt6 = "DATE OF BIRTH:";
  var txt7 = "GENDER:";
  var txt8 = "Choose type";
  var txt9 = "Men's";
  var txt10 = "Women's";
  var txt11 = "NATIONALITY:";
  var txt12 = "Choose country";
  var txt13 = "HEIGHT (IN METERS):";
  var txt14 = "WEIGHT (IN KILOGRAMS):";
  var txt15 = "SIDELINE";
  var txt16 = "Choose leg";
  var txt17 = "Right-footed";
  var txt18 = "Left-footed";
  var txt19 = "Ambidextrous";
  var txt20 = "MAIN POSITION:";
  var txt21 = "Choose a position";
  var txt22 = "Goalkeeper";
  var txt23 = "Defense";
  var txt24 = "Midfielder";
  var txt25 = "Forward";
  var txt26 = "ALTERNATIVE POSITION:";
  var txt27 = "UPDATE";
  var txt28 = "CANCEL";
  var txt29 = "ADDITIONAL TAGS SEPARATED BY COMMA (For example: defense, left, fullback, fast)";
  var txt30 = "Please complete all fields.";
  
  function changeLang(){
    var x = localStorage.getItem('idiom');

    if( x == "esp"){
      //console.log("Lang: Español");
      txt1 = "Editar perfil";
      txt2 = "NOMBRE(S):";
      txt3 = "APELLIDO(S):";
      txt4 = "BIO:";
      txt5 = "Máximo 100 caracteres";
      txt6 = "FECHA DE NACIMIENTO:";
      txt7 = "RAMA:";
      txt8 = "Elige rama";
      txt9 = "Masculino";
      txt10 = "Femenil";
      txt11 = "NACIONALIDAD:";
      txt12 = "Elige país";
      txt13 = "ALTURA (EN METROS):";
      txt14 = "PESO (EN KILOGRAMOS):";
      txt15 = "LATERALIDAD";
      txt16 = "Elige pierna";
      txt17 = "Diestro";
      txt18 = "Zurdo";
      txt19 = "Ambidiestro";
      txt20 = "POSICIÓN PRINCIPAL:";
      txt21 = "Elige una posición";
      txt22 = "Portero";
      txt23 = "Defensa";
      txt24 = "Centrocampista";
      txt25 = "Delantero";
      txt26 = "POSICIÓN ALTERNATIVA:";
      txt27 = "ACTUALIZAR";
      txt28 = "CANCELAR";
      txt29 = "TAGS ADICIONALES SEPARADAS POR COMA (Por ejemplo: defensa, lateral, izquierdo, veloz)";
      txt30 = "Por favor, complete todos los campos.";
    }
    else{
      //console.log("Lang: English");
    }
  }

  changeLang();


  //USE EFFECT
  useEffect(() => {
    
  },[]);

  //
  const navigate = useNavigate();

  function cancelEdit(){
    navigate('/User');
    setReloader(reloader + 1);
  }

  //
  var d = new Date();
  const formik = useFormik ({
    initialValues:{
        name: activeuser.name,
        lastname: activeuser.lastname,
        bio: activeuser.bio,
        birthday: activeuser.birthday,
        gender: activeuser.gender,
        nationality: activeuser.nationality,
        height: activeuser.height,
        weight: activeuser.weight,
        dominant_leg: activeuser.dominant_leg,
        position: activeuser.position,
        sec_position: activeuser.sec_position,
        addtags: activeuser.addtags,
        mod_type: '6',
        mod_update: d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3),
    },
    onSubmit: reg =>{
      if(
        !reg.name ||
        !reg.lastname ||
        !reg.bio ||
        !reg.birthday ||
        !reg.gender ||
        !reg.nationality ||
        !reg.height ||
        !reg.weight ||
        !reg.dominant_leg ||
        !reg.position ||
        !reg.sec_position ||
        !reg.addtags
      ){
        alert(txt30);
      }else{
        reg.tags = [reg.name.toLowerCase(), reg.lastname.toLowerCase(), reg.nationality.toLowerCase(), reg.gender.toLowerCase()].concat( reg.position.split('/').map(word => word.toLowerCase()), reg.sec_position.split('/').map(word => word.toLowerCase()), reg.addtags.split(/[ ,]+/).map(word => word.toLowerCase()) ); 
        //console.log(reg);
        savePlayer(reg);
      } 
    }
  })

  //INPUT SLIDERS
  function changeH(a){
    var x = document.getElementById(a).value;
    
    setH(x.toString());
  }

  function changeW(a){
    var x = document.getElementById(a).value;
    
    setW(x.toString());
  }

  return (
    <>
    <Top/>
    <div className="Main">

     <div className="log_box">
        <h1>{txt1}</h1>
        <form onSubmit={formik.handleSubmit} className="fields">
          <div className="field">
            <label htmlFor="name">{txt2}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="lastname">{txt3}</label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="bio">{txt4}</label>
            <textarea
              id="bio"
              name="bio"
              maxLength="100"
              value={formik.values.bio}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt5}
            >
            </textarea>
          </div>
          <div className="field">
            <label htmlFor="birthday">{txt6}</label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              value={formik.values.birthday}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="gender">{txt7}</label>
            <select 
              id="gender"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt8}</option>
              <option value="Men's">{txt9}</option>
              <option value="Women's">{txt10}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="nationality">{txt11}</label>
            <select 
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  Countries.map((item, index) => (
                    <option value={item.en}>{item.en}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
              <label htmlFor="height">{txt13 + " "} {formik.values.height == undefined ? 0 : formik.values.height}</label>
            <input
              type="range"
              id="height"
              name="height"
              step=".01"
              min="0"
              max="2.2"
              defaultValue="0"
              value={formik.values.height}
              onInput={()=>changeH('height')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="weight">{txt14 + " "} {formik.values.weight == undefined ? 0 : formik.values.weight}</label>
            <input
              type="range"
              id="weight"
              name="weight"
              step=".1"
              min="0"
              max="120"
              defaultValue="0"
              value={formik.values.weight}
              onInput={()=>changeW('weight')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="dominant_leg">{txt15}</label>
            <select 
              id="dominant_leg"
              name="dominant_leg"
              value={formik.values.dominant_leg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt16}</option>
              <option value="Right-footed">{txt17}</option>
              <option value="Left-footed">{txt18}</option>
              <option value="Ambidextrous">{txt19}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="position">{txt20}</label>
            <select 
              id="position"
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt21}</option>
              <option value="Goalkeeper/Portero">{txt22}</option>
              <option value="Defense/Defensa">{txt23}</option>
              <option value="Midfielder/Centrocampista">{txt24}</option>
              <option value="Forward/Delantero">{txt25}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="sec_position">{txt26}</label>
            <select 
              id="sec_position"
              name="sec_position"
              value={formik.values.sec_position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt21}</option>
              <option value="Goalkeeper/Portero">{txt22}</option>
              <option value="Defense/Defensa">{txt23}</option>
              <option value="Midfielder/Centrocampista">{txt24}</option>
              <option value="Forward/Delantero">{txt25}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="addtags">{txt29}</label>
            <input
              type="text"
              id="addtags"
              name="addtags"
              value={formik.values.addtags}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <button type="submit" className="btn">{txt27}</button>
        </form>
        <button className="btn_cancel" onClick={cancelEdit}>{txt28}</button>
     </div>
    </div>
    </>

  );
}

export default Profile;