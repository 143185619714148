import React, {useEffect, useState, useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';

const Coach = () => {

    //
    var u = window.location.pathname;
    var l = u.substr(7);
    //console.log(u);
    //console.log(l);
    
    //
    const { premium, premiumsub, getCoach, coach, activeuser, createChat, buySub } = useContext(DataContext);

    //
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [age, setAge] = useState(0);

    //TEXTS IDIOMS
    var txt1 = "Verified";
    var txt2 = "Not verified";
    var txt3 = "MESSAGE";
    var txt4 = "BECOME A PREMIUM USER TO SEND MESSAGES!";
    var txt5 = "Birth date:";
    var txt6 = "Age:";
    var txt7 = "Nationality:";
    var txt8 = "Current team:";
    var txt9 = "No team/agency";
    var txt10 = "Resume";
    var txt11 = "Start:";
    var txt12 = "End:";
    var txt13 = "There are no teams/agencies.";
    var txt14 = "Videos";
    var txt15 = "No videos yet.";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "Verificado";
            txt2 = "No verificado";
            txt3 = "MENSAJE";
            txt4 = "¡CONVIÉRTETE EN USUARIO PREMIUM PARA ENVIARLE UN MENSAJE!";
            txt5 = "Fecha de nacimiento:";
            txt6 = "Edad:";
            txt7 = "Nacionalidad:";
            txt8 = "Equipo actual:";
            txt9 = "Sin equipo/agencia";
            txt10 = "Currículum";
            txt11 = "Inicio:";
            txt12 = "Salida:";
            txt13 = "No hay equipos/agencias.";
            txt14 = "Videos";
            txt15 = "No hay videos aún.";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(async () => {
        await getCoach(l);
        getAge(coach.birthday); 
        setReady(true);
    },[age]);

    //
    function setChat(){
        var creator_name = activeuser.name + ' ' + activeuser.lastname;
        var receptor_name =  coach.name + ' ' + coach.lastname;
        var creator_profile = activeuser.profile;
        var receptor_profile = coach.profile;
        createChat( nanoid(12), activeuser.key_id, coach.key_id, creator_name, receptor_name, creator_profile, receptor_profile );
    }


    //
    const navigate = useNavigate();
    
    //
    function goPage(a){
        navigate(a)
    }

     //
     function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var aged = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            aged--;
        }
        setAge(aged);
        //console.log(age);
    }
    

    return(
        <div>
            <Top/>
            <div className="main">

                <div className="profile_box">
                    
                    <div className="profile_top">
                        <div className="profile_photo">
                            <div className="profile_photo_im">
                                {
                                    coach.photoURL
                                    ?
                                    <img className="profile_pic" src={coach.photoURL} alt="Foto de perfil"/>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="profile_data">
                            <h1>{coach.name + ' ' + coach.lastname}</h1>
                            <p className="pos_top_profile">{coach.profile ? coach.profile : '--'}</p>
                            <div className="verificated_line">
                                {
                                    coach.verificated == "1"
                                    ?
                                <p className="verificated"><span>&#10003; </span>{txt1}</p>
                                    :
                                    <p className="noverificated">{txt2}</p>
                                }
                            </div>
                        </div>
                        <div className="clear"></div>
                        
                        {/* SOLO CON CONEXION A STRIPE
                            activeuser.key_id != coach.key_id
                            ?
                            <div className="subtop_buttons">
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                    ?
                                    <button className="btn_min" onClick={setChat}>{txt3}</button>
                                    :
                                    <button className="btn_min_gold" onClick={buySub}>{txt4}</button>
                                }   
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'canceled' || premiumsub === 'incomplete' || premiumsub === 'incomplete_expired' || premiumsub === 'past_due' ||  premiumsub === 'unpaid')
                                    ?
                                    <p className="error_sub">An error occurred with the payment of your monthly subscription.</p>
                                    :
                                    null
                                }   
                            </div>
                            :
                            null
                        */}

                        {
                            activeuser.key_id != coach.key_id
                            ?
                            <div className="subtop_buttons">
                                <button className="btn_min" onClick={setChat}>{txt3}</button>    
                            </div>
                            :
                            null
                        }
                    </div>

                    <div className="profile_timeline">
                   
                        <div className="profile_bio">
                            <p>{coach.bio ? coach.bio : '--'}</p>
                        </div>
                        
                        <div className="profile_stats">
                            <div className="stat">
                                <p className="stat_t">{txt5}</p>
                                <p className="stat_d">{coach.birthday ? coach.birthday : '--'}</p>
                            </div>
                            <div className="stat">
                                <p className="stat_t">{txt6}</p>
                                <p className="stat_d">{isNaN(age) || age === 0 ? null : age}</p>
                            </div>
                            <div className="stat">
                                <p className="stat_t">{txt7}</p>
                                <p className="stat_d">{coach.nationality ? coach.nationality : '--'}</p>
                            </div>
                            <div className="stat">
                                {
                                    coach.teams && coach.teams.length > 0
                                    ?
                                    coach.teams.filter(x=> x.team_end === '').map((item, index) => (
                                        <div key={index}>
                                            <p className="stat_t">{txt8}</p>
                                            <p className="stat_d">{item.team_name}</p>
                                        </div>
                                    ))
                                    :
                                    <p className="stat_t">{txt9}</p>
                                }    
                            </div>
                        </div>

                        <div className="profile_boxes">
                            <h2>{txt10}</h2>    
                            <div className="teams_list">
                                {
                                    coach.teams && coach.teams.length > 1
                                    ?
                                    coach.teams.slice()
                                    .sort((a, b) => {
                                        if (a.team_end === "" && b.team_end !== "") {
                                            return -1; // Colocar a al principio
                                        } else if (a.team_end !== "" && b.team_end === "") {
                                            return 1; // Colocar b al principio
                                        } else {
                                            return new Date(b.team_end) - new Date(a.team_end); // Ordenar por fechas
                                        }
                                    }).map((item, index) => (
                                        <div key={index} className="team_element">
                                            <p className="list_name">{item.team_name}</p>
                                            <p className="list_pos">{item.team_position}</p>
                                            <p className="list_pos">{item.team_league}</p>
                                            <p className="list_pos"> {txt11 + " " + item.team_init} | {txt12 + " " +item.team_end}</p>
                                        </div>
                                    ))
                                    :
                                    <div>{txt13}</div>
                                }
                            </div>
                        </div>

                        <div className="profile_boxes">
                            <h2>{txt14}</h2>
                            <div className="videos_list">
                                {
                                coach.videos && coach.videos.length > 0
                                ?
                                coach.videos.map((item, index) => (
                                    <div key={index} className="video_element">
                                        <div dangerouslySetInnerHTML={{__html: item }} />
                                    </div>
                                ))
                                :
                                <div>{txt15}</div>
                                }
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>

                    <div className="clear"></div>

                </div>
                
            </div>

        </div>

    );
}

export default Coach;