import React from 'react';
import { Link } from 'react-router-dom';

import Top from './top.js';

const Error404 = () => {
   


    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>ERROR 404</h1>
            </div>

        </div>
        </>
    );


}

export default Error404;