import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';
import Countries from './countries.json';

const Players = () => {

    //
    const { getPlayers, allplayers, searchListPlayers, nextPlayers, filterList, lastreg } = useContext(DataContext);

    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [reloader, setReloader] = useState(1);

    //TEXTS IDIOMS
    var txt1 = "PLAYERS";
    var txt2 = "NO RESULTS";
    var txt3 = "Search by name, position or nationality";
    var txt4 = "SEARCH";
    var txt5 = "Search by filters";
    var txt6 = "Type";
    var txt7 = "Men's";
    var txt8 = "Women's";
    var txt9 = "Position";
    var txt10 = "Goalkeeper";
    var txt11 = "Defender";
    var txt12 = "Midfielder";
    var txt13 = "Forward";
    var txt14 = "Availability";
    var txt15 = "I am not in any club and I am available immediately";
    var txt16 = "I am in a club and I am available immediately without compensation (open contract)";
    var txt17 = "I am in a club and I am available with severance pay (buy out)";
    var txt18 = "I am in a club and I am available under conditions";
    var txt19 = "I am in a club and I am open to new offers";
    var txt20 = "Verified";
    var txt21 = "Not verified";
    var txt22 = "Nationality";
    var txt23 = "Choose country";
    var txt24 = "Min. age";
    var txt25 = "Max. age";
    var txt26 = "Min. height";
    var txt27 = "Max. height";
    var txt28 = "FILTER";
    var txt29 = "LOAD MORE";
 
    function changeLang(){
        var x = localStorage.getItem('idiom');
 
        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "JUGADORES";
            txt2 = "SIN RESULTADOS";
            txt3 = "Busca por nombre, posición o nacionalidad";
            txt4 = "BUSCAR";
            txt5 = "Búsqueda por filtros";
            txt6 = "Rama";
            txt7 = "Masculino";
            txt8 = "Femenil";
            txt9 = "Posición";
            txt10 = "Portero";
            txt11 = "Defensa";
            txt12 = "Centrocampista";
            txt13 = "Delantero";
            txt14 = "Disponibilidad";
            txt15 = "No estoy en ningún club y estoy disponible inmediatamente";
            txt16 = "Estoy en un club y estoy disponible inmediatamente sin indemnización (open contract)";
            txt17 = "Estoy en un club y estoy disponible con indemnización (buy out)";
            txt18 = "Estoy en un club y estoy disponible bajo condiciones";
            txt19 = "Estoy en un club y estoy abierto a nuevas ofertas";
            txt20 = "Verificado";
            txt21 = "No verificado";
            txt22 = "Nacionalidad";
            txt23 = "Elige país";
            txt24 = "Edad mín.";
            txt25 = "Edad máx.";
            txt26 = "Altura mín.";
            txt27 = "Altura máx.";
            txt28 = "FILTRAR";
            txt29 = "CARGAR MÁS";
           
        }
        else{
            //console.log("Lang: English");
        }
    }
 
    changeLang();


    //USE EFFECT
    useEffect(() => {
        fillArray();
    },[ready, reloader]);

    //
    async function fillArray(){
        try {
            await getPlayers();
            setList(allplayers);
            //console.log(list);
            //console.log(list.length);
            nextP();
            setReady(true);
        } catch (error) {
            //console.log(error);
        }
    }

    //SEARCH ENGINE
    async function searching(){
        var a = document.getElementById("search_field").value.toLowerCase();
        var b = a.split(' ');
        var y = "players";
        await searchListPlayers(b, y);
        setList(allplayers);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);

    }

    //NEXT PAGE
    async function nextP(){
        if(lastreg == undefined){
            //console.log("Init")
        }else{
            await nextPlayers('players');
            setList(allplayers);
        }  
    }


    //SEARCH BY FILTERS
    async function searchFilter(){
        var a = document.getElementById("gender_field").value;
        var b = document.getElementById("position_field").value;
        var c = document.getElementById("status_field").value;
        var d = document.getElementById("verified_field").value;
        var e = document.getElementById("nationality_field").value;
        var f = document.getElementById("min_age_field").value;
        var g = document.getElementById("max_age_field").value;
        var h = document.getElementById("min_h_field").value;
        var i = document.getElementById("max_h_field").value;

        await filterList(a, b, c, d, e, f, g, h, i, 'players');
        
        setList(allplayers);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);
    }

    //INPUT SLIDERS
    function changeNum(a, b){
        var x = document.getElementById(a).value;
        var y = document.getElementById(b);
       
        y.innerHTML = x.toString();
    }

    //RESET
    function resetPage(){
        window.location.reload();
    }

    return(
        <>
        <Top/>
        <div className="main">
            <div className="list_box">
                <h1>{txt1}</h1>
                <div className="search_box">
                    <div className="search_area">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt3}
                        />
                        <button className="btn_min" onClick={searching}>{txt4}</button>
                    </div>
                    <div className="advanced_box">
                        <p>{txt5}</p>
                        <div className="search_inp">
                            <label htmlFor="gender_field">{txt6}</label>
                            <select id="gender_field" name="gender_field">
                                <option value="Men's">{txt7}</option>
                                <option value="Women's">{txt8}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="position_field">{txt9}</label>
                            <select id="position_field" name="positon_field">
                            <option value="Goalkeeper/Portero">{txt10}</option>
                            <option value="Defense/Defensa">{txt11}</option>
                            <option value="Midfielder/Centrocampista">{txt12}</option>
                            <option value="Forward/Delantero">{txt13}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="status_field">{txt14}</label>
                            <select id="status_field">
                            <option value="1">{txt15}</option>
                            <option value="2">{txt16}</option>
                            <option value="3">{txt17}</option>
                            <option value="4">{txt18}</option>
                            <option value="5">{txt19}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="verified_field">{txt20}</label>
                            <select id="verified_field">
                                <option value="1">{txt20}</option>
                                <option value="">{txt21}</option>
                            </select>
                        </div>
                        <div className="search_inp">
                            <label htmlFor="nationality_field">{txt22}</label>
                            <select id="nationality_field">
                                {
                                    Countries.map((item, index) => (
                                        <option value={item.en}>{item.en}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="min_age_field">{txt24}</label>
                            <p id="min_age_output">16</p>
                            <div className="clear"></div>
                            <input type="range" id="min_age_field" name="min_age_field" min="16" max="50" step="1" defaultValue="16" onChange={()=>changeNum('min_age_field', 'min_age_output')}/>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="max_age_field">{txt25}</label>
                            <p id="max_age_output">18</p>
                            <div className="clear"></div>
                            <input type="range" id="max_age_field" name="max_age_field" min="16" max="50" step="1" defaultValue="18" onChange={()=>changeNum('max_age_field', 'max_age_output')}/>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="min_age_field">{txt26}</label>
                            <p id="min_h_output">1.40</p>
                            <div className="clear"></div>
                            <input type="range" id="min_h_field" name="min_h_field" min="1.40" max="2.20" step=".01" defaultValue="1.40" onChange={()=>changeNum('min_h_field', 'min_h_output')}/>
                        </div>
                        <div className="search_inp2">
                            <label htmlFor="min_age_field">{txt27}</label>
                            <p id="max_h_output">1.60</p>
                            <div className="clear"></div>
                            <input type="range" id="max_h_field" name="max_h_field"min="1.40" max="2.20" step=".01" defaultValue="1.60" onChange={()=>changeNum('max_h_field', 'max_h_output')}/>
                        </div>

                        <div className="clear"></div>
                        <button className="btn_min" onClick={searchFilter}>{txt28}</button>
                    </div>
                </div>
                {
                list.length == 0
                ?
                <div className="loading_msg">{txt2}</div>
                :
                list.map((item, index) => (
                    <Link to={`/Player/${ item.id }`}  key={item.id} className="list_item">
                        <div className="list_photo"> 
                            {
                                item.photoURL
                                ?
                                <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                :
                                null
                            }
                        </div>
                        <div className="list_data">
                            <p className="list_name">{item.name + " " + item.lastname}</p>
                            <p className="list_pos">{item.gender + ' ' + 'league'}</p>
                            <p className="list_pos">{item.position + " | " + item.nationality}</p>
                            {
                                        item.status == "1"
                                        ?
                                        <p className="list_pos">{txt15}</p>
                                        :
                                        null
                                }  
                                {
                                        item.status == "2"
                                        ?
                                        <p className="list_pos">{txt16}</p>
                                        :
                                        null
                                } 
                                {
                                        item.status == "3"
                                        ?
                                        <p className="list_pos">{txt17}</p>
                                        :
                                        null
                                }
                                {
                                        item.status == "4"
                                        ?
                                        <p className="list_pos">{txt18}</p>
                                        :
                                        null
                                }
                                {
                                        item.status == "5"
                                        ?
                                        <p className="list_pos">{txt19}</p>
                                        :
                                        null
                                }                   
                        </div>
                        <div className="clear"></div>
                    </Link>
                ))}
                <div className="load_more_btn">
                    <button id="load_btn" className="btn_min" onClick={nextP}>{txt29}</button>
                    <button id="reset_btn" className="btn_min" onClick={resetPage}>RESET</button>
                </div>
               
            </div>
        </div>
        </>

    );


}

export default Players;