import React from 'react';
import { Link } from 'react-router-dom';

import l1 from '../images/insta.png';
import l2 from '../images/face.png';
import l3 from '../images/x.png';

const Footer = () => {

  
    return (
        <div className="footer">
            <div className="socials">
                <a href="https://www.instagram.com/conexsport/" target="_blank"><img src={l1} alt=""/></a>
                <a href="https://www.facebook.com/ConnexSports" target="_blank"><img src={l2} alt=""/></a>
                <a href="https://twitter.com/ConexSport" target="_blank"><img src={l3} alt=""/></a>
            </div>
            <Link to="/Privacy">Privacy Policy</Link>
            <p>&copy; 2024 ConexSport. All rights reserved.</p>
            <p>Version 1.1</p>
        </div>

    );
}

export default Footer;