import React, {useEffect, useState, useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { DataContext } from '../firebase/datacontext.js';

import Top from '../elementos/top.js';

const Agent = () => {
    
    //
    var u = window.location.pathname;
    var l = u.substr(7);
    //console.log(u);
    //console.log(l);
    
    //
    const { setVisited, premium, premiumsub,  getAgent, agent, getUser, activeuser, getAgentPlayers, agentplayers, createChat, buySub, searchChat } = useContext(DataContext);

    
    //TEXTS IDIOMS
    var txt1 = "Verified";
    var txt2 = "Not verified";
    var txt3 = "FIFA verificated";
    var txt4 = "FIFA no verified";
    var txt5 = "-16 verificated";
    var txt6 = "-16 no verified";
    var txt7 = "MESSAGE";
    var txt8 = "BECOME A PREMIUM USER TO SEND MESSAGES!";
    var txt9 = "Birth date:";
    var txt10 = "Age:";
    var txt11 = "Nationality:";
    var txt12 = "Current team";
    var txt13 = "No team/agency";
    var txt14 = "PLAYERS REPRESENTED";
    var txt15 = "SHOW ALL";
    var txt16 = "No players represented yet";
    var txt17 = "Resume";
    var txt18 = "Start:";
    var txt19 = "End:";
    var txt20 = "There are no teams/agencies.";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "Verificado";
            txt2 = "No verificado";
            txt3 = "Verificación FIFA";
            txt4 = "Sin verificación FIFA";
            txt5 = "Verificado -16";
            txt6 = "Sin verificación -16";
            txt7 = "MENSAJE";
            txt8 = "¡CONVIÉRTETE EN USUARIO PREMIUM PARA ENVIARLE UN MENSAJE!";
            txt9 = "Fecha de nacimiento:";
            txt10 = "Edad:";
            txt11 = "Nacionalidad:";
            txt12 = "Equipo actual:";
            txt13 = "Sin equipo/agencia";
            txt14 = "JUGADORES REPRESENTADOS";
            txt15 = "VER TODOS";
            txt16 = "Aún no tiene jugadores representados";
            txt17 = "Currículum";
            txt18 = "Inicio:";
            txt19 = "Salida:";
            txt20 = "No hay equipos/agencias.";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();
    
    //
    const [isMine, setIsmine] = useState(false);

    //
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [age, setAge] = useState(0);

    //USE EFFECT
    useEffect(async () => {
        await getAgent(l);
        await fillArray();
        setVisited(l);
        getAge(agent.birthday); 
       
    },[age]);

    //
    async function fillArray(){
        try {
            await getAgentPlayers(agent.key_id, "1");
            setList(agentplayers);
            //console.log(list)
            setReady(true);
        } catch (error) {
            //console.log(error);
        }
    }

    //
    function setChat(){
        var creator_name = activeuser.name + ' ' + activeuser.lastname;
        var receptor_name =  agent.name + ' ' + agent.lastname;
        var creator_profile = activeuser.profile;
        var receptor_profile = agent.profile;
        createChat( nanoid(12), activeuser.key_id, agent.key_id, creator_name, receptor_name, creator_profile, receptor_profile );
    }

    //
    function checkRep(){
        if(agent.players){
            var x = agent.players.filter(n => n.player_id == activeuser.key_id);
            if(x[0].player_id == activeuser.key_id){
                setIsmine(true);
                //console.log(isMine);

            }else{
                setIsmine(false); 
                //console.log(isMine);         
            }
               
        }else{
            //console.log("No tiene jugadores representados");
        }   
    }

    //
    const navigate = useNavigate();
    
    //
    function goPage(a){
        navigate(a)
    }

    //
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var aged = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            aged--;
        }
        setAge(aged);
        //console.log(age);
    }
    

    return(
        <div>
            <Top/>
            <div className="main">

                <div className="profile_box">
                    
                    <div className="profile_top">
                        <div className="profile_photo">
                            <div className="profile_photo_im">
                                {
                                    agent.photoURL
                                    ?
                                    <img className="profile_pic" src={agent.photoURL} alt="Foto de perfil"/>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="profile_data">
                            <h1>{agent.name + ' ' + agent.lastname}</h1>
                            <p className="pos_top_profile">{agent.profile ? agent.profile : '--'}</p>
                            <div className="verificated_line">
                                {
                                    agent.verificated == "1"
                                    ?
                                    <p className="verificated"><span>&#10003; </span>{txt1}</p>
                                    :
                                    <p className="noverificated">{txt2}</p>
                                }
                            </div>
                            <div className="verificated_line">
                                {
                                    agent.verificated_fifa == "1"
                                    ?
                                    <p className="verificated2">{txt3}</p>
                                    :
                                    <p className="noverificated">{txt4}</p>
                                }
                            </div>
                            <div className="verificated_line">
                                {
                                    agent.verificated_six == "1"
                                    ?
                                    <p className="verificated2">{txt5}</p>
                                    :
                                    <p className="noverificated">{txt6}</p>
                                    }
                            </div>
                        </div>
                        <div className="clear"></div>
                        
                        {/* SOLO CON CONEXION A STRIPE
                            activeuser.key_id != agent.key_id
                            ?
                            <div className="subtop_buttons">
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                    ?
                                    <button className="btn_min" onClick={setChat}>{txt7}</button>
                                    :
                                    <button className="btn_min_gold" onClick={buySub}>{txt8}</button>
                                }   
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'canceled' || premiumsub === 'incomplete' || premiumsub === 'incomplete_expired' || premiumsub === 'past_due' ||  premiumsub === 'unpaid')
                                    ?
                                    <p className="error_sub">An error occurred with the payment of your monthly subscription.</p>
                                    :
                                    null
                                }   
                            </div>
                            :
                            null
                        */}

                        {
                            activeuser.key_id != agent.key_id
                            ?
                            <div className="subtop_buttons">  
                                <button className="btn_min" onClick={setChat}>{txt7}</button>     
                            </div>
                            :
                            null
                        }


                    </div>

                    <div className="profile_timeline">
                        
                        <div className="profile_bio">
                            <p>{agent.bio ? agent.bio : '--'}</p>
                        </div>
                        
                        <div className="profile_stats">
                            <div className="stat">
                                <p className="stat_t">{txt9}</p>
                                <p className="stat_d">{agent.birthday ? agent.birthday : '--'}</p>
                            </div>
                            <div className="stat">
                                <p className="stat_t">{txt10}</p>
                                <p className="stat_d">{isNaN(age) || age === 0 ? null : age}</p>
                            </div>
                            <div className="stat">
                                <p className="stat_t">{txt11}</p>
                                <p className="stat_d">{agent.nationality ? agent.nationality : '--'}</p>
                            </div>
                            <div className="stat">
                                {
                                    agent.teams && agent.teams.length > 0
                                    ?
                                    agent.teams.filter(x=> x.team_end === '').map((item, index) => (
                                        <div key={index}>
                                            <p className="stat_t">{txt12}</p>
                                            <p className="stat_d">{item.team_name}</p>
                                        </div>
                                    ))
                                    :
                                    <p className="stat_t">{txt13}</p>
                                }    
                            </div>
                        </div>

                        <div className="profile_stats">
                            <div className="stat">
                                <div className="profile_tt">
                                    <h3>{txt14}</h3>
                                    <button className="btn_min" onClick={() => goPage('/AgentRoster')}>{txt15}</button>
                                </div>
                                {
                                    list.length == 0
                                    ?
                                    <div>{txt16}</div>
                                    :
                                    <div className="profile_list">
                                        {list.slice(0, 10).map((item, index) => (
                                            <Link key={index} className='list_item5' to={`/Player/${ item.id }`}>
                                                <div className="list_photo"> 
                                                    {
                                                        item.photoURL
                                                        ?
                                                        <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="list_data3">
                                                    <p className="list_name">{item.name + " " + item.lastname}</p>
                                                    <p className="list_pos">{item.position + " | " + item.nationality}</p>
                                                </div>
                                                <div className="clear"></div>
                                            </Link>
                                        ))}
                                        <div className="clear"></div>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="profile_boxes">
                            <h2>{txt17}</h2>    
                            <div className="teams_list">
                                {
                                    agent.teams && agent.teams.length > 1
                                    ?
                                    agent.teams.slice()
                                    .sort((a, b) => {
                                        if (a.team_end === "" && b.team_end !== "") {
                                            return -1; // Colocar a al principio
                                        } else if (a.team_end !== "" && b.team_end === "") {
                                            return 1; // Colocar b al principio
                                        } else {
                                            return new Date(b.team_end) - new Date(a.team_end); // Ordenar por fechas
                                        }
                                    }).map((item, index) => (
                                        <div key={index} className="team_element">
                                            <p className="list_name">{item.team_name}</p>
                                            <p className="list_pos">{item.team_position}</p>
                                            <p className="list_pos">{item.team_league}</p>
                                            <p className="list_pos">{txt18 + ' ' + item.team_init} | {txt19 + ' ' + item.team_end}</p>
                                        </div>
                                    ))
                                    :
                                    <div>{txt20}</div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="clear"></div>
                
                </div>
                
            </div>
        </div>

    );
}

export default Agent;