import React from 'react';
import { Link } from 'react-router-dom';

import Top from './top.js';
import politics from '../docs/politica.pdf';

const Privacy = () => {


    //TEXTS IDIOMS
    var txt1 = "PRIVACY POLICY";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "POLÍTICA DE PRIVACIDAD";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();


    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt1}</h1>
                <div>
                    <iframe src={politics} width='100%' height='500px' />
                </div>
            </div>

        </div>
        </>

    );


}

export default Privacy;