import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';
import { nanoid } from 'nanoid';

import Top from './top.js';
import Leagues from './ligas.json';
import LeaguesWomen from './ligas_women.json';
import LeaguesMen from './ligas_men.json';
import Countries from './countries.json';

import { DataContext } from '../firebase/datacontext.js';

const OpenOffer = () => {
  
  //
  const { activeuser, createOffer, setOffer, createChat } = useContext(DataContext);

  //TEXTS IDIOMS
  var txt1 = "Write public offer";
  var txt2 = "OFFER:";
  var txt3 = "Maximum 100 characters.";
  var txt4 = "NATIONALITY OF PLAYER:";
  var txt5 = "Choose country";
  var txt6 = "COUNTRY OF OFFER:";
  var txt7 = "LEAGUE:";
  var txt8 = "Choose a league";
  var txt9 = "REQUIRED POSITION:";
  var txt10 = "Choose a position";
  var txt11 = "Goalkeeper";
  var txt12 = "Defense";
  var txt13 = "Midfielder";
  var txt14 = "Forward";
  var txt15 = "SECONDARY POSITION:";
  var txt16 = "Choose a secondary position";
  var txt17 = "GENDER:";
  var txt18 = "Chose type";
  var txt19 = "Men's";
  var txt20 = "Women's";
  var txt21 = "SIDE";
  var txt22 = "Choose leg";
  var txt23 = "Right-footed";
  var txt24 = "Left-footed";
  var txt25 = "Ambidextrous";
  var txt26 = "AGE:";
  var txt27 = "HEIGHT (IN METERS):";
  var txt28 = "DESIRED PROJECT:";
  var txt29 = "Choose project";
  var txt30 = "100% sport";
  var txt31 = "Employment plus sport";
  var txt32 = "Study/Training plus sport";
  var txt33 = "MONTHLY SALARY (USD):";
  var txt34 = "Choose monthly salary (USD)";
  var txt35 = "SEND";
  var txt36 = "CANCEL";
  var txt37 = "Proposal sent.";

  function changeLang(){
    var x = localStorage.getItem('idiom');

    if( x == "esp"){
      //console.log("Lang: Español");
      txt1 = "Redactar oferta pública";
      txt2 = "OFERTA:";
      txt3 = "Máximo 100 caracteres.";
      txt4 = "NACIONALIDAD DEL JUGADOR:";
      txt5 = "Elige país";
      txt6 = "PAÍS DE LA OFERTA:";
      txt7 = "LIGA:";
      txt8 = "Elige una liga";
      txt9 = "POSICIÓN REQUERIDA:";
      txt10 = "Elige una posición";
      txt11 = "Portero";
      txt12 = "Defensa";
      txt13 = "Centrocampista";
      txt14 = "Delantero";
      txt15 = "POSICIÓN SECUNDARIA:";
      txt16 = "Elige una posición secundaria";
      txt17 = "RAMA:";
      txt18 = "Elige rama";
      txt19 = "Masculino";
      txt20 = "Femenil";
      txt21 = "LATERALIDAD";
      txt22 = "Elige pierna";
      txt23 = "Diestro";
      txt24 = "Zurdo";
      txt25 = "Ambidiestro";
      txt26 = "EDAD:";
      txt27 = "ALTURA (EN METROS):";
      txt28 = "PROYECTO DESEADO:";
      txt29 = "Elige proyecto";
      txt30 = "100% deporte";
      txt31 = "Empleo más deporte";
      txt32 = "Estudio/Formación más deporte";
      txt33 = "SUELDO MENSUAL (USD):";
      txt34 = "Elige sueldo mensual (USD)";
      txt35 = "ENVIAR";
      txt36 = "CANCELAR";
      txt37 = "Propuesta enviada.";
    }
    else{
      //console.log("Lang: English");
    }
  }

  changeLang();


  //USE EFFECT
  useEffect(() => {
    
  },[]);

  //
  const navigate = useNavigate();

  function cancelEdit(){
    navigate('/User')
  }

  //
  var d = new Date();
  const formik = useFormik ({
    initialValues:{
        offer: '',
        nationality: '',
        offer_country: '',
        league: '',
        position: '',
        sec_position: '',
        gender: '',
        dominant_leg: '',
        age: '',
        height: '',
        project:'',
        pay:'',
        update: d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3),
    },
    onSubmit: reg =>{
      reg.tags = [reg.offer_country.toLowerCase(), reg.league.toLowerCase(), reg.nationality.toLowerCase(), reg.position.toLowerCase(), reg.sec_position.toLowerCase()];
      reg.creator = activeuser.key_id;
      reg.creator_name = activeuser.name + " " + activeuser.lastname;
      reg.receptor = "open";
      reg.receptor_name = "Open offer";
      reg.chatid = nanoid(12);
      reg.messages = [];
      //console.log(reg);
      createOffer(reg.chatid, reg);
      //setOffer(activeuser.key_id, reg);
      //createChat(reg.chatid, reg.offer);
      alert(txt37);
    }
  })

  //
  function changeType(){
    var x = document.getElementById('gender').value;
    var y = document.getElementById('field_m');
    var z = document.getElementById('field_w');

    if(x == "Men's"){
      y.style.display = "block";
      z.style.display = "none";
    }
    else{
      z.style.display = "block";
      y.style.display = "none";
    }
  }

  return (
    <>
    <Top/>
    <div className="Main">

     <div className="log_box">
      <h1>{txt1}</h1>
        <form onSubmit={formik.handleSubmit} className="fields">
          
          <div className="field">
            <label htmlFor="bio">{txt2}</label>
            <textarea
              id="offer"
              name="offer"
              maxLength="200"
              value={formik.values.offer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt3}
            >
            </textarea>
          </div>
          <div className="field">
            <label htmlFor="nationality">{txt4}</label>
            <select 
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  Countries.map((item, index) => (
                    <option value={item.en}>{item.en}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
            <label htmlFor="offer_country">{txt6}</label>
            <select 
              id="offer_country"
              name="offer_country"
              value={formik.values.offer_country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  Countries.map((item, index) => (
                    <option value={item.en}>{item.en}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
            <label htmlFor="gender">{txt17}</label>
            <select 
              id="gender"
              name="gender"
              value={formik.values.gender}
              onChange={(e) => { formik.handleChange(e); changeType() }}
              onBlur={formik.handleBlur}>
              <option value="">{txt18}</option>
              <option value="Men's">{txt19}</option>
              <option value="Women's">{txt20}</option>
            </select>
          </div>
          <div className="field" id="field_m">
            <label htmlFor="league">{txt7}</label>
            <select 
              id="league"
              name="league"
              value={formik.values.league}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  LeaguesMen.map((item, index) => (
                    <option value={item.pais + ' - ' + item.liga}>{item.pais + ' - ' + item.liga}</option>
                  ))
                }
            </select>
          </div>
          <div className="field" id="field_w">
            <label htmlFor="league">{txt7}</label>
            <select 
              id="league"
              name="league"
              value={formik.values.league}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  LeaguesWomen.map((item, index) => (
                    <option value={item.pais + ' - ' + item.liga}>{item.pais + ' - ' + item.liga}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
            <label htmlFor="position">{txt9}</label>
            <select 
              id="position"
              name="position"
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt10}</option>
              <option value="Goalkeeper/Portero">{txt11}</option>
              <option value="Defense/Defensa">{txt12}</option>
              <option value="Midfielder/Centrocampista">{txt13}</option>
              <option value="Forward/Delantero">{txt14}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="sec_position">{txt15}</label>
            <select 
              id="sec_position"
              name="sec_position"
              value={formik.values.sec_position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt16}</option>
              <option value="Goalkeeper/Portero">{txt11}</option>
              <option value="Defense/Defensa">{txt12}</option>
              <option value="Midfielder/Centrocampista">{txt13}</option>
              <option value="Forward/Delantero">{txt14}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="dominant_leg">{txt21}</label>
            <select 
              id="dominant_leg"
              name="dominant_leg"
              value={formik.values.dominant_leg}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt22}</option>
              <option value="Right-footed">{txt23}</option>
              <option value="Left-footed">{txt24}</option>
              <option value="Ambidextrous">{txt25}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="age">{txt26}</label>
            <input
            type="text"
            id="age"
            name="age"
            maxLength="2"
            value={formik.values.age}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}>
            </input>
          </div>
          <div className="field">
            <label htmlFor="height">{txt27}</label>
            <input
              type="number"
              id="height"
              name="height"
              step=".01"
              min="1.3"
              max="2.5"
              placeholder="1.5"
              value={formik.values.height}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="project">{txt28}</label>
            <select 
              id="project"
              name="project"
              value={formik.values.project}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt29}</option>
              <option value="100% sport">{txt30}</option>
              <option value="Employment plus sport">{txt31}</option>
              <option value="Training plus sport">{txt32}</option>
            </select>
          </div>
          <div className="field">
            <label htmlFor="pay">{txt33}</label>
            <select 
              id="pay"
              name="pay"
              value={formik.values.pay}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="">{txt34}</option>
              <option value="0 - 500">0 - 500</option>
              <option value="500 - 1000">500 - 1000</option>
              <option value="1000 - 2000">1000 - 2000</option>
              <option value="2000 - 3000">2000 - 3000</option>
              <option value="3000 - 4000">3000 - 4000</option>
              <option value="4000 - 5000">4000 - 5000</option>
            </select>
          </div>
          <button type="submit" className="btn">{txt35}</button>
        </form>
        <button className="btn_cancel" onClick={cancelEdit}>{txt36}</button>
     </div>
    </div>
    </>

  );
}

export default OpenOffer;