import React from 'react';
import ReactWhatsapp from 'react-whatsapp';

import whats from '../images/whatsapp_icono.png';

const WhatsApp = () => { 

return (
   <div className="whats_icon">
     <ReactWhatsapp number="+34 662098095" message="Hello" element="none">
        <img src={whats} alt="WhatsApp"/>
     </ReactWhatsapp>
   </div>
  );
}

export default WhatsApp;