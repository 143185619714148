import React, {useEffect, useState, useContext} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Club = () => {

    //
    var u = window.location.pathname;
    var l = u.substr(6);
    //console.log(u);
    //console.log(l);
    
    //
    const { premium, premiumsub, getClub, club, activeuser, createChat, buySub } = useContext(DataContext);

    //
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "Verified";
    var txt2 = "Not verified";
    var txt3 = "MESSAGE";
    var txt4 = "BECOME A PREMIUM USER TO SEND MESSAGES!";
    var txt5 = "Founding date:";
    var txt6 = "Country:";
    var txt7 = "Current representative:";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "Verificado";
            txt2 = "No verificado";
            txt3 = "MENSAJE";
            txt4 = "¡CONVIÉRTETE EN USUARIO PREMIUM PARA ENVIARLE UN MENSAJE!";
            txt5 = "Fecha de fundación:";
            txt6 = "País:";
            txt7 = "Representante actual:";

        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(() => {
        getClub(l);
       
    },[]);

    //
    function setChat(){
        var creator_name = activeuser.name + ' ' + activeuser.lastname;
        var receptor_name =  club.name + ' ' + club.lastname;
        var creator_profile = activeuser.profile;
        var receptor_profile = club.profile;
        createChat( nanoid(12), activeuser.key_id, club.key_id, creator_name, receptor_name, creator_profile, receptor_profile );
    }


    //
    const navigate = useNavigate();
    
    //
    function goPage(a){
        navigate(a)
    }
    

    return(
        <div>
            <Top/>
            <div className="main">

                <div className="profile_box">
                    
                    <div className="profile_top">
                        <div className="profile_photo">
                            <div className="profile_photo_im">
                                {
                                    club.photoURL
                                    ?
                                    <img className="profile_pic" src={club.photoURL} alt="Foto de perfil"/>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="profile_data">
                            <h1>{club.name}</h1>
                            <p>{club.league ? club.league : '--'} | {club.nationality ? club.nationality : '--'}</p>
                            <div className="verificated_line">
                                {
                                    club.verificated == "1"
                                    ?
                                    <p className="verificated"><span>&#10003; </span>{txt1}</p>
                                    :
                                    <p className="noverificated">{txt2}</p>
                                }
                            </div>
                        </div>
                        <div className="clear"></div>

                        {/*  SOLO CON CONEXION A STRIPE
                            activeuser.key_id != club.key_id
                            ?
                            <div className="subtop_buttons">
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'active' || premiumsub === 'trialing')
                                    ?
                                    <button className="btn_min" onClick={setChat}>{txt3}</button>
                                    :
                                    <button className="btn_min_gold" onClick={buySub}>{txt4}</button>
                                }   
                                {
                                    (premium != "" || premium != undefined) && (premiumsub === 'canceled' || premiumsub === 'incomplete' || premiumsub === 'incomplete_expired' || premiumsub === 'past_due' ||  premiumsub === 'unpaid')
                                    ?
                                    <p className="error_sub">An error occurred with the payment of your monthly subscription.</p>
                                    :
                                    null
                                }   
                            </div>
                            :
                            null
                        */}
                        
                        {
                            activeuser.key_id != club.key_id
                            ?
                            <div className="subtop_buttons">
                                <button className="btn_min" onClick={setChat}>{txt3}</button>    
                            </div>
                            :
                            null
                        }
                    </div>
                        
                    <div className="profile_timeline">
                        <div className="profile_bio">
                            <p>{club.bio ? club.bio : '--'}</p>
                        </div>
                        
                        <div className="profile_stats">
                            <div className="stat">
                                <p className="stat_t">{txt5}</p>
                                <p className="stat_d">{club.birthday ? club.birthday : '--'}</p>
                            </div>
                            <div className="stat">
                                <p className="stat_t">{txt6}</p>
                                <p className="stat_d">{club.nationality ? club.nationality : '--'}</p>
                            </div>
                            <div className="stat">
                                <p className="stat_t">{txt7}</p>
                                <p className="stat_d">{club.current_rep ? club.current_rep : '--'}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="clear"></div>

                </div>
                
            </div>

        </div>

    );
}

export default Club;