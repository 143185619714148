import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Following = () => {

    //
    const {flplayers, activeuser } = useContext(DataContext);

    const [mainlist, setMainlist] = useState([]);
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "PLAYERS YOU FOLLOW";
    var txt2 = "NO RESULTS";
    var txt3 = "Search by name, position or country";
    var txt4 = "I'm not in any club and I'm available immediately";
    var txt5 = "I am in a club and I am available immediately without compensation (open contract)";
    var txt6 = "I am in a club and I am available with compensation (buy out)";
    var txt7 = "I'm in a club and I'm available under conditions";
    var txt8 = "I'm in a club and I'm open to new offers";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "JUGADORES QUE SIGUES";
            txt2 = "SIN RESULTADOS";
            txt3 = "Buscar por nombre, posición o país";
            txt4 = "No estoy en ningún club y estoy disponible inmediatamente";
            txt5 = "Estoy en un club y estoy disponible inmediatamente sin indemnización (open contract)";
            txt6 = "Estoy en un club y estoy disponible con indemnización (buy out)";
            txt7 = "Estoy en un club y estoy disponible bajo condiciones";
            txt8 = "Estoy en un club y estoy abierto a nuevas ofertas";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();


    //USE EFFECT
    useEffect(() => {
        fillFollow();
     },[]);

    
    function fillFollow(){
        setList(flplayers);
        setMainlist(flplayers);
        //console.log(list);
    }


    //SEARCH ENGINE
    function searching(){
        var list2 = mainlist;
        var a = document.getElementById("search_field").value.toLowerCase();
        var x = list2.filter(x => {
            return x.name.toLowerCase().includes(a) || x.position.toLowerCase().includes(a) || x.lastname.toLowerCase().includes(a) || x.nationality.toLowerCase().includes(a);
        });
        //console.log(x);
        setList(x)
        //console.log(list);
       
    }

    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt1}</h1>

                <div>
                    <div className="search_box">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt3}
                            onChange={searching}
                        />
                    </div>
                    {
                    list.length == 0
                    ?
                    <div className="loading_msg">{txt2}</div>
                    :
                    list.map((item, index) => (
                        <Link to={`/${ item.profile }/${ item.id }`}  key={item.id} className="list_item">
                            <div className="list_photo"> 
                                {
                                    item.photoURL
                                    ?
                                    <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                    :
                                    null
                                }
                            </div>
                            <div className="list_data">
                                <p className="list_name">{item.name + " " + item.lastname}</p>
                                <p className="list_pos">{item.position + " | " + item.nationality}</p>
                                {
                                        item.status == "1"
                                        ?
                                        <p className="list_pos">{txt4}</p>
                                        :
                                        null
                                }  
                                {
                                        item.status == "2"
                                        ?
                                        <p className="list_pos">{txt5}</p>
                                        :
                                        null
                                } 
                                {
                                        item.status == "3"
                                        ?
                                        <p className="list_pos">{txt6}</p>
                                        :
                                        null
                                }
                                {
                                        item.status == "4"
                                        ?
                                        <p className="list_pos">{txt7}</p>
                                        :
                                        null
                                }
                                {
                                        item.status == "5"
                                        ?
                                        <p className="list_pos">{txt8}</p>
                                        :
                                        null
                                }                   
                            </div>
                            <div className="clear"></div>
                        </Link>
                    ))}
                </div>
            </div>                       
        </div>
        </>

    );


}

export default Following;