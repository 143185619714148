import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Followers = () => {

    //
    const { flagents, flcoaches, flclubs, activeuser } = useContext(DataContext);

    const [mainlist, setMainlist] = useState([]);
    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);

    //TEXTS IDIOMS
    var txt1 = "FOLLOWERS";
    var txt2 = "NO RESULTS";
    var txt3 = "Search by name";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "SEGUIDORES";
            txt2 = "SIN RESULTADOS";
            txt3 = "Buscar por nombre";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();


    //USE EFFECT
    useEffect(() => {
        fillFollow();
     },[]);

    
    function fillFollow(){
        setList(flagents.concat(flcoaches, flclubs));
        setMainlist(flagents.concat(flcoaches, flclubs));
        //console.log(list);
    }

    //SEARCH ENGINE
    function searching(){
        var list2 = mainlist;
        var a = document.getElementById("search_field").value.toLowerCase();
        var x = list2.filter(x => {
            return x.name.toLowerCase().includes(a) || x.lastname.toLowerCase().includes(a);
        });
        //console.log(x);
        setList(x)
        //console.log(list);
       
    }

    return(
        <>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt1}</h1>

                <div>
                    <div className="search_box">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt3}
                            onChange={searching}
                        />
                    </div>
                    {
                    list.length == 0
                    ?
                    <div className="loading_msg">{txt2}</div>
                    :
                    list.map((item, index) => (
                        <Link to={`/${ item.profile }/${ item.id }`}  key={item.id} className="list_item">
                            <div className="list_photo"> 
                                {
                                    item.photoURL
                                    ?
                                    <img className="list_pic" src={item.photoURL} alt="Foto de perfil"/>
                                    :
                                    null
                                }
                            </div>
                            <div className="list_data">
                                <p className="list_name">{item.name + " " + item.lastname}</p>
                                <p className="list_pos">{item.profile + " | " + item.nationality}</p>
                                <p className="list_pos">{item.current_team}</p>
                            </div>
                            <div className="clear"></div>
                        </Link>
                    ))}
                </div>
            </div>

        </div>
        </>

    );


}

export default Followers;