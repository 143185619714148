import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Top from './top.js';

import { DataContext } from '../firebase/datacontext.js';

const Proposals = () => {

    //
    var u = window.location.pathname;
    var l = u.substr(11);
    //console.log(u);
    //console.log(l);

    //
    const [msg, setMsg] = useState('');
    
    //
    var chatAuth = 'chat_auth';
    var chatNoauth ='chat_noauth';

    //
    const { saveMsgOffer, getMsgOffer, offerMsgs, activeuser} = useContext(DataContext);

    //TEXTS IDIOMS
    var txt1 = "PUBLIC OFFER MESSAGE CENTER";
    var txt2 = "Country: ";
    var txt3 = "League: ";
    var txt4 = "Salary: ";
    var txt5 = "Project: ";
    var txt6 = "No messages";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "CENTRO DE MENSAJES DE PROPUESTA PÚBLICA";
            txt2 = "País: ";
            txt3 = "Liga: ";
            txt4 = "Salario: ";
            txt5 = "Proyecto: ";
            txt6 = "Sin mensajes";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();


    //
    useEffect(() => {
     getMsgOffer(l);
    },[]);


    return(

        <>
           <Top/>
           <div className="Main">

                <div className="chat_window">
                    <div className="chat_title">
                        <h1>CENTRO DE MENSAJES DE PROPUESTA PÚBLICA</h1>
                        <h2>{offerMsgs.offer}</h2>
                        <div className="chat_info">
                            <p>País: <strong>{offerMsgs.offer_country}</strong></p>
                            <p>Liga: <strong>{offerMsgs.league}</strong></p>
                            <p>Salario: <strong>{offerMsgs.pay}</strong></p>
                            <p>Proyecto: <strong>{offerMsgs.project}</strong></p>
                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="chat_area">
                        <div className="chat_box">
                            {
                                offerMsgs.length == 0
                                ?
                                <p>Sin mensajes</p>
                                :
                                offerMsgs.messages.map((item, index) => (
                                    <div key={index}>
                                        <div className={ activeuser.key_id == item.author ? chatAuth : chatNoauth }>
                                            {
                                                activeuser.key_id == item.author
                                                ?
                                                null
                                                :
                                                <Link 
                                                className="chat_u"
                                                to= {`/${ item.author_profile }/${ item.author }`}>
                                                    {item.name}
                                                </Link>
                                            }
                                            <p className="chat_m">{item.text}</p>
                                            
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                ))
                            }
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
            
            </div>
        </>

    );

}

export default Proposals;