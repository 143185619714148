import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';

import Top from './top.js';
import Leagues from './ligas.json';
import LeaguesWomen from './ligas_women.json';
import LeaguesMen from './ligas_men.json';
import Countries from './countries.json';

import { DataContext } from '../firebase/datacontext.js';

const Profile = () => {
  
  //
  const { activeuser, saveClub, setReloader, reloader } = useContext(DataContext);

  //TEXTS IDIOMS
  var txt1 = "Edit profile";
  var txt2 = "TEAM NAME:";
  var txt3 = "DESCRIPTION:";
  var txt4 = "Maximum 100 characters";
  var txt5 = "FOUNDING DATE:";
  var txt6 = "NATIONALITY:";
  var txt7 = "Choose country";
  var txt8 = "LEAGUE:";
  var txt9 = "Choose a league";
  var txt10 = "REPRESENTATIVE'S NAME:";
  var txt11 = "UPDATE";
  var txt12 = "CANCEL";
  var txt13 = "TYPE:";
  var txt14 = "Men's";
  var txt15 = "Women's";
  var txt30 = "Please complete all fields.";
  
  
  function changeLang(){
    var x = localStorage.getItem('idiom');

    if( x == "esp"){
      //console.log("Lang: Español");
      txt1 = "Editar perfil";
      txt2 = "NOMBRE DEL EQUIPO:";
      txt3 = "DESCRIPCIÓN:";
      txt4 = "Máximo 100 caracteres";
      txt5 = "FECHA DE FUNDACIÓN:";
      txt6 = "NACIONALIDAD:";
      txt7 = "Elige país";
      txt8 = "LIGA:";
      txt9 = "Elige una liga";
      txt10 = "NOMBRE DEL REPRESENTANTE:";
      txt11 = "ACTUALIZAR";
      txt12 = "CANCELAR";
      txt13 = "RAMA:";
      txt14 = "Masculino";
      txt15 = "Femenil";
      txt30 = "Por favor, complete todos los campos.";
    }
    else{
      //console.log("Lang: English");
    }
  }

  changeLang();


  //USE EFFECT
  useEffect(() => {
    initType();
  },[]);

  //
  const navigate = useNavigate();

  function cancelEdit(){
    navigate('/User');
    setReloader(reloader + 1);
  }

  //
  var d = new Date();
  const formik = useFormik ({
    initialValues:{
        name: activeuser.name,
        lastname: '',
        bio: activeuser.bio,
        birthday: activeuser.birthday,
        nationality: activeuser.nationality,
        gender: activeuser.gender,
        league: activeuser.league,
        current_rep: activeuser.current_rep,
        mod_type: '6',
        mod_update: d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3),
    },
    onSubmit: reg =>{
      if(
        !reg.name ||
        !reg.bio ||
        !reg.birthday ||
        !reg.nationality ||
        !reg.gender ||
        !reg.league ||
        !reg.current_rep
      ){
        alert(txt30);
      }else{
        reg.tags = [reg.name.toLowerCase(), reg.nationality.toLowerCase(), reg.league.toLowerCase()];   
        //console.log(reg);
        saveClub(reg);
      } 
    }
  })

  //
  function initType(){
    var y = document.getElementById('field_m');
    var z = document.getElementById('field_w');

    if(activeuser.gender == "Women's"){
      z.style.display = "block";
      y.style.display = "none";
    }
    else{
      y.style.display = "block";
      z.style.display = "none";
    }
  }

  //
  function changeType(){
    var x = document.getElementById('gender').value;
    var y = document.getElementById('field_m');
    var z = document.getElementById('field_w');

    if(x == "Men's"){
      y.style.display = "block";
      z.style.display = "none";
    }
    else{
      z.style.display = "block";
      y.style.display = "none";
    }
  }


  return (
    <>
    <Top/>
    <div className="Main">

     <div className="log_box">
        <h1>{txt1}</h1>
        <form onSubmit={formik.handleSubmit} className="fields">
          <div className="field">
            <label htmlFor="name">{txt2}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="bio">{txt3}</label>
            <textarea
              id="bio"
              name="bio"
              maxLength="100"
              value={formik.values.bio}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt4}
            >
            </textarea>
          </div>
          <div className="field">
            <label htmlFor="birthday">{txt5}</label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              value={formik.values.birthday}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="nationality">{txt6}</label>
            <select 
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  Countries.map((item, index) => (
                    <option value={item.en}>{item.en}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
            <label htmlFor="gender">{txt13}</label>
            <select 
              id="gender"
              name="gender"
              value={formik.values.gender}
              onChange={(e) => { formik.handleChange(e); changeType() }}
              onBlur={formik.handleBlur}>
              <option value="Men's">{txt14}</option>
              <option value="Women's">{txt15}</option>
            </select>
          </div>
          <div className="field" id="field_m">
            <label htmlFor="league">{txt8}</label>
            <select 
              id="league"
              name="league"
              value={formik.values.league}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  LeaguesMen.map((item, index) => (
                    <option value={item.pais + ' - ' + item.liga}>{item.pais + ' - ' + item.liga}</option>
                  ))
                }
            </select>
          </div>
          <div className="field" id="field_w">
            <label htmlFor="league">{txt8}</label>
            <select 
              id="league"
              name="league"
              value={formik.values.league}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  LeaguesWomen.map((item, index) => (
                    <option value={item.pais + ' - ' + item.liga}>{item.pais + ' - ' + item.liga}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
            <label htmlFor="current_team">{txt10}</label>
            <input
              type="text"
              id="current_rep"
              name="current_rep"
              value={formik.values.current_rep}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <button type="submit" className="btn">{txt11}</button>
        </form>
        <button className="btn_cancel" onClick={cancelEdit}>{txt12}</button>
     </div>
    </div>
    </>

  );
}

export default Profile;