import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import Logo from '../images/logo.png';
import politics from '../docs/politica.pdf';

import { DataContext } from '../firebase/datacontext.js';

const Register = () => {
  
  //
  const { registerUser } = useContext(DataContext);


  //
  const [mail, setMail] = useState();
  const [pass, setPass] = useState();
  const [profile, setProfile] = useState();

  //TEXTS IDIOMS
  var txt1 = "Registration";
  var txt2 = "PROFILE:";
  var txt3 = "Profile"
  var txt4 = "Player";
  var txt5 = "Agent";
  var txt6 = "Coach";
  var txt7 = "Club";
  var txt8 = "REGISTER";
  var txt9 = "I have read and accept the terms and conditions of use"

  function changeLang(){
    var x = localStorage.getItem('idiom');

      if( x == "esp"){
        //console.log("Lang: Español");
        txt1 = "Registro";
        txt2 = "PERFIL:";
        txt3 = "Perfil";
        txt4 = "Jugador";
        txt5 = "Agente";
        txt6 = "Entrenador";
        txt7 = "Club";
        txt8 = "REGISTRAR";
        txt9 = "He leído y acepto los términos y condiciones de uso";
      }
      else{
          //console.log("Lang: English");
      }
  }

  changeLang();

  //
  function regUser(){
    var x = document.getElementById('profile');
    var y = document.getElementById('terms')

    if(x.value != '' && y.checked == true){
      registerUser(mail, pass, profile)
    }else{
      alert("Capture all the required data.")
    }
  }

 

  return (
    <div className="intro">
      <div className="log_box">
        <img src={Logo} alt="logo"/>
        <h1>{txt1}</h1>
        <div className="fields">
          <div className="field">
            <label htmlFor="email">EMAIL:</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              onChange={e => setMail(e.target.value)}
            />
          </div>
          <div className="field">
            <label htmlFor="lastname">PASSWORD:</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              onChange={e => setPass(e.target.value)}
            />
          </div>
          <div className="field">
            <label htmlFor="profile">{txt2}</label>
            <select 
              id="profile"
              name="profile"
              required
              onChange={e => setProfile(e.target.value)}>
              <option value="">{txt3}</option>
              <option value="Player">{txt4}</option>
              <option value="Agent">{txt5}</option>
              <option value="Coach">{txt6}</option>
              <option value="Club">{txt7}</option>
            </select>
          </div>
        </div>
        <div className="check_politic">
          <input type="checkbox" id="terms" name="terms" value="accept"/>
          <label for="terms"><a href={politics} target="_blank" className="terms_link">{txt9}</a></label>
          <div className="clear"></div>
        </div>
        <button className="btn" onClick={regUser}>{txt8}</button>
      </div>
    </div>

  );
}

export default Register;