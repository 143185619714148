import React, {useEffect, useState, useContext} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import Logo from '../images/logo.png';

import { DataContext } from '../firebase/datacontext.js';


const Top = () => {

    
    //
    const navigate = useNavigate();

    //
    const { activeuser, logOut, getMessages, filtermessages, checkmsgs } = useContext(DataContext);

    //
    const [ready, setReady] = useState(false);
    const [lang, selectLang] = useState(localStorage.getItem('idiom'));


    //USE EFFECT
    useEffect(() => {
        //console.log(ready);
    },[ready]);

    //
    async function goOut(){
        await logOut();
        navigate('/');
        window.location.reload();

    }

    //
    function goPage(a){
        navigate(a)
    }

    //
    function goProfile(){
        if(activeuser.email){
            navigate('/User');
        }else{
            navigate('/Login');
        }  
    }

    //
    function goWall(){
        if(activeuser.email){
            navigate('/Wall');
        }else{
            navigate('/Login');
        }  
    }

    //
    function setLang(a){
        localStorage.setItem('idiom', a);
        //console.log(localStorage.getItem('idiom'));

        setTimeout(() => {
            window.location.reload();
          }, 500);
        
    }

    //TEXTS IDIOMS
    var txt1 = "Profile";
    var txt2 = "New messages:";
    var txt3 = "Players";
    var txt4 = "Agents";
    var txt5 = "Coaches";
    var txt6 = "Clubs";
    var txt7 = "Offers";
    var txt8 = "Events";
    var txt9 = "Activity";

    function changeLang(){
        var x = localStorage.getItem('idiom');
        
        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "Mi perfil";
            txt2 = "Nuevos mensajes:";
            txt3 = "Jugadores";
            txt4 = "Agentes";
            txt5 = "Entrenadores";
            txt6 = "Clubs";
            txt7 = "Ofertas";
            txt8 = "Eventos";
            txt9 = "Actividad";
          
            
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(async () => {
        if(activeuser.email){
            await getMessages();
            setReady(true);
        }
        else{
            //console.log('No hay sesion activa');
        }
    },[ready]);

    return (
        <div>
            <div className="top">
                <div className="lang_selector">
                    <button id="lang_sel_esp" className={ lang == "esp" ? "selected_lang" : null} onClick={() => setLang('esp')}>{'\u{1F1EA}\u{1F1F8}'}</button>
                    <button id="lang_sel_eng" className={ lang == "eng" || lang === null ? "selected_lang" : null} onClick={() => setLang('eng')}>{'\u{1F1EC}\u{1F1E7}'}</button>
                </div>
                <img onClick={goWall} className="im_top" src={Logo}  alt="Logo"/>
        
                <div className="user_top">
                    <p onClick={goOut}>{activeuser.email ? 'Log Out' : null}</p>
                    { activeuser.email ? <p onClick={() => goPage("/Messages")}>{txt2} <span className="new_msgs">{filtermessages.length}</span></p> : null}
                    <p onClick={goProfile}>{activeuser.email ? txt1 : 'Login'}</p>
                    {/* activeuser.email ? <p onClick={goWall}>{txt9}</p> : null*/}
                </div>
                <div className="clear"></div>
            </div>
            {
            activeuser.email
            ?
            <div className="menu if_link">
                <NavLink className={({isActive}) => isActive ? "menulinkactive" : "menulink"} to='/Players'>{txt3}</NavLink>
                <NavLink className={({isActive}) => isActive ? "menulinkactive" : "menulink"} to='/Agents'>{txt4}</NavLink>
                <NavLink className={({isActive}) => isActive ? "menulinkactive" : "menulink"} to='/Coaches'>{txt5}</NavLink>
                <NavLink className={({isActive}) => isActive ? "menulinkactive" : "menulink"} to='/Clubs'>{txt6}</NavLink>
                <p>|</p>
                <NavLink className={({isActive}) => isActive ? "menulinkactive" : "menulink"} to='/Offers'>{txt7}</NavLink>
                <NavLink className={({isActive}) => isActive ? "menulinkactive" : "menulink"} to='/Events'>{txt8}</NavLink>
            </div>
            :
            null
            }
        </div>

    );
}

export default Top;