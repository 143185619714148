import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';

import Top from './top.js';
import Countries from './countries.json';

import { DataContext } from '../firebase/datacontext.js';

const Profile = () => {
  
  //
  const { activeuser, saveCoach, setReloader, reloader } = useContext(DataContext);

  //TEXTS IDIOMS
  var txt1 = "Edit profile";
  var txt2 = "FIRSTNAME(S):";
  var txt3 = "SURNAME(S):";
  var txt4 = "BIO:";
  var txt5 = "Maximum 100 characters";
  var txt6 = "DATE OF BIRTH:";
  var txt7 = "NATIONALITY:";
  var txt8 = "Choose country";
  var txt9 = "UPDATE";
  var txt10 = "CANCEL";
  var txt30 = "Please complete all fields.";
  
  function changeLang(){
    var x = localStorage.getItem('idiom');

    if( x == "esp"){
      //console.log("Lang: Español");
      txt1 = "Editar perfil";
      txt2 = "NOMBRE(S):";
      txt3 = "APELLIDO(S):";
      txt4 = "BIO:";
      txt5 = "Máximo 100 caracteres";
      txt6 = "FECHA DE NACIMIENTO:";
      txt7 = "NACIONALIDAD:";
      txt8 = "Elige país";
      txt9 = "ACTUALIZAR";
      txt10 = "CANCELAR";
      txt30 = "Por favor, complete todos los campos.";
    }
    else{
      //console.log("Lang: English");
    }
  }

  changeLang();



  //USE EFFECT
  useEffect(() => {
    
  },[]);

  //
  const navigate = useNavigate();

  function cancelEdit(){
    navigate('/User');
    setReloader(reloader + 1);
  }

  //
  var d = new Date();
  const formik = useFormik ({
    initialValues:{
        name: activeuser.name,
        lastname: activeuser.lastname,
        bio: activeuser.bio,
        birthday:activeuser.birthday,
        nationality:activeuser.nationality,
        mod_type: '6',
        mod_update: d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3),
    },
    onSubmit: reg =>{
      if(
        !reg.name ||
        !reg.lastname ||
        !reg.bio ||
        !reg.birthday ||
        !reg.nationality
      ){
        alert(txt30);
      }else{
        reg.tags = [reg.name.toLowerCase(), reg.lastname.toLowerCase(), reg.nationality.toLowerCase()];   
        //console.log(reg);
        saveCoach(reg);
      } 
    }
  })


  return (
    <>
    <Top/>
    <div className="Main">

     <div className="log_box">
        <h1>{txt1}</h1>
        <form onSubmit={formik.handleSubmit} className="fields">
          <div className="field">
            <label htmlFor="name">{txt2}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="lastname">{txt3}</label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="bio">{txt4}</label>
            <textarea
              id="bio"
              name="bio"
              maxLength="100"
              value={formik.values.bio}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt5}
            >
            </textarea>
          </div>
          <div className="field">
            <label htmlFor="birthday">{txt6}</label>
            <input
              type="date"
              id="birthday"
              name="birthday"
              value={formik.values.birthday}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="field">
            <label htmlFor="nationality">{txt7}</label>
            <select 
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  Countries.map((item, index) => (
                    <option value={item.en}>{item.en}</option>
                  ))
                }
            </select>
          </div>
          <button type="submit" className="btn">{txt9}</button>
        </form>
        <button className="btn_cancel" onClick={cancelEdit}>{txt10}</button>
     </div>
    </div>
    </>

  );
}

export default Profile;