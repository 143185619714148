import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';

import { DataContext } from '../firebase/datacontext.js';

import Top from './top.js';

const Offers = () => {

    //
    const { getOffers, alloffers, activeuser, saveMsgOffer, searchListOffers, nextOffers, lastreg } = useContext(DataContext);

    const [list, setList] = useState([]);
    const [ready, setReady] = useState(false);
    const [reloader, setReloader] = useState(1);

    //
    const [msg, setMsg] = useState('');
    const [idchat, setIdchat] = useState('');

    //TEXTS IDIOMS
    var txt1 = "Apply for this offer";
    var txt2 = "APPLY";
    var txt3 = "CANCEL";
    var txt4 = "Write your message";
    var txt5 = "PUBLIC OFFERS";
    var txt6 = "Search by country, position, or league";
    var txt7 = "NO RESULTS";
    var txt8 = "APPLY";
    var txt9 = "Created by: ";
    var txt10 = "Country of the offer: ";
    var txt11 = "League: ";
    var txt12 = "Position required: ";
    var txt13 = "Type: ";
    var txt14 = "Age: ";
    var txt15 = "Nationality: ";
    var txt16 = "Height: ";
    var txt17 = "Side: ";
    var txt18 = "Secondary position: ";
    var txt19 = "Project: ";
    var txt20 = "Salary: ";
    var txt21 = "See more";
    var txt22 = "LOAD MORE";
    var txt23 = "SEARCH";

    function changeLang(){
        var x = localStorage.getItem('idiom');

        if( x == "esp"){
            //console.log("Lang: Español");
            txt1 = "Postularte para esta oferta";
            txt2 = "POSTULARME";
            txt3 = "CANCELAR";
            txt4 = "Escribe tu mensaje";
            txt5 = "OFERTAS PÚBLICAS";
            txt6 = "Busca por país, posición, o liga";
            txt7 = "SIN RESULTADOS";
            txt8 = "POSTULARME";
            txt9 = "Creador: ";
            txt10 = "País de la oferta: ";
            txt11 = "Liga: ";
            txt12 = "Posición requerida: ";
            txt13 = "Rama: ";
            txt14 = "Edad: ";
            txt15 = "Nacionalidad: ";
            txt16 = "Altura: ";
            txt17 = "Lateralidad: ";
            txt18 = "Posición secundaria: ";
            txt19 = "Proyecto: ";
            txt20 = "Sueldo: ";
            txt21 = "Ver más";
            txt22 = "CARGAR MÁS";
            txt23 = "BUSCAR";
        }
        else{
            //console.log("Lang: English");
        }
    }

    changeLang();

    //USE EFFECT
    useEffect(() => {
        fillArray();
    },[ready, reloader]);

    //
    async function fillArray(){
        try {
            await getOffers();
            setList(alloffers);
            //console.log(list);
            //console.log(list.length);
            nextP();
            setReady(true);
        } catch (error) {
            //console.log(error);
        }
    }

    //SEARCH ENGINE
    async function searching(){
        var a = document.getElementById("search_field").value.toLowerCase();
        var b = a.split(' ');
        var y = "offers";
        await searchListOffers(b, y);
        setList(alloffers);
        document.getElementById('load_btn').style.display = "none";
        document.getElementById('reset_btn').style.display = "inline";
        setReloader(reloader + 1);
    }

    //NEXT PAGE
    async function nextP(){
        if(lastreg == undefined){
            //console.log("Init")
        }else{
            await nextOffers('offers');
            setList(alloffers);
        }  
    }

    //RESET
    function resetPage(){
        window.location.reload();
    }

    //EXPAND MORE INFORMATION
    function expandInfo(a, b){
        var x = document.getElementById(a);
        var y = document.getElementById(b);

        if(x.style.display == 'block'){
            x.style.display = 'none';
            y.innerHTML = "Ver más";
        }
        else {
            x.style.display = 'block';
            y.innerHTML = "Ver menos";
        }
    }

    //
    async function postMsg(){
        var x = document.getElementById('offer_txt');
        if(x.value != ''){
            await saveMsgOffer(idchat, {
                text: msg,
                author: activeuser.key_id,
                author_profile: activeuser.profile,
                name: activeuser.name + " " + activeuser.lastname
            });
            x.value = '';
            closePop('popup1')

        }else{
            alert(txt4);
        }  
    }

    //
    function openPop(p, i){
        document.getElementById(p).style.display = "flex";
        setIdchat(i);
    }

    //
    function closePop(p){
        document.getElementById(p).style.display = "none";
    }

    return(
        <>
        <div className="popup" id="popup1">
            <div className="popup_box2">
                <p className="popup_t2">{txt1}</p>
                <input className="offer_txt" id="offer_txt"
                    type="text"
                    placeholder={txt4}
                    onChange={e => setMsg(e.target.value)}
                />
                <div className="pop_buttons">
                    <button className="btn" onClick={postMsg}>{txt2}</button>
                    <button className="btn_cancel" onClick={() => closePop('popup1')}>{txt3}</button>
                </div>
            </div>
        </div>
        <Top/>
        <div className="main">
           
            <div className="list_box">
                <h1>{txt5}</h1>
                <div className="search_box">
                <div className="search_area">
                        <input
                            type="text"
                            id="search_field"
                            name="search_field"
                            placeholder={txt6}
                        />
                        <button className="btn_min" onClick={searching}>{txt23}</button>
                    </div>
                </div>
                {
                list.length == 0
                ?
                <div className="loading_msg">{txt7}</div>
                :
                list.map((item, index) => (
                    <div key={item.chatid} className="list_item2">
                        <div className="list_data">
                            <p className="list_name">{item.offer}</p>
                            {
                                activeuser.profile == "Player"
                                ?
                                <button className="btn_min" onClick={() => openPop('popup1', item.chatid)}>{txt8}</button>
                                :
                                null
                            }
                            <div className="list_data2">
                                <Link className='textlink4' to={`/${ item.creator_profile }/${ item.creator }`}><strong>{txt9}</strong> {item.creator_name}</Link>
                                <p className="list_pos"><strong>{txt10}</strong> {item.offer_country}</p>
                                <p className="list_pos"><strong>{txt11}</strong> {item.league}</p>
                                <p className="list_pos"><strong>{txt12}</strong> {item.position}</p>
                                <p className="list_pos"><strong>{txt13}</strong> {item.gender}</p>
                                <p className="list_pos"><strong>{txt14}</strong> {item.age}</p>
                            </div>
                            <button className="btn_min" onClick={() => expandInfo(item.chatid, 'btn' + item.chatid)} id={'btn' + item.chatid}>{txt21}</button>
                            <div className="list_more" id={item.chatid}>
                                <p className="list_pos"><strong>{txt15}</strong> {item.nationality}</p>
                                <p className="list_pos"><strong>{txt16}</strong> {item.height}</p>
                                <p className="list_pos"><strong>{txt17}</strong> {item.dominant_leg}</p>
                                <p className="list_pos"><strong>{txt18}</strong> {item.sec_position}</p>
                                <p className="list_pos"><strong>{txt19}</strong> {item.project}</p>
                                <p className="list_pos"><strong>{txt20}</strong> {item.pay}</p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                ))}
                <div className="load_more_btn">
                    <button id="load_btn" className="btn_min" onClick={nextP}>{txt22}</button>
                    <button id="reset_btn" className="btn_min" onClick={resetPage}>RESET</button>
                </div>
            </div>
            
        </div>
        </>

    );


}

export default Offers;