import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {db, auth, storage} from './firebaseConfig';
import { doc, addDoc, setDoc, collection, getDocs, getDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove, query, onSnapshot, orderBy, limit, where, deleteField, startAfter } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";

export const DataContext = createContext();



export const DataProvider = ({children}) => {

    //USE NAVIGATE
    const navigate = useNavigate();
    
    //REFERENCE COLLECTIONS
    const refplayer = collection(db, "players");
    const refagent = collection(db, "agents");
    const refcoach = collection(db, "coaches");
    const refclub = collection(db, "clubs");
    const refchat = collection(db, "chat");
    const refoffers = collection(db, "offers");
    const refevents = collection(db, "events");

    //CURRENT USER DATA
    const [user_id, setUserid] = useState('');
    const [user_email, setUseremail] = useState('');
    
    //REV USER
    function revUser(){
        if(auth.currentUser == null){
            //console.log("No hay sesión activa");
        }else{
            //console.log(auth.currentUser.uid);
            //console.log(auth.currentUser.email);
        }
    }

    //STATES
    const [premium, setPremium] = useState('');
    const [premiumsub, setPremiumsub] = useState('');
    const [activeuser, setActiveuser] = useState([]);
    const [allplayers, setAllplayers] = useState([]);
    const [agentplayers, setAgplayers] = useState([]);
    const [flplayers, setFlplayers] = useState([]);
    const [flagents, setFlagents] = useState([]);
    const [flcoaches, setFlcoaches] = useState([]);
    const [flclubs, setFlclubs] = useState([]);
    const [playeragent, setPlagent] = useState([]);
    const [wallplayers, setWallplayers] = useState([]);
    const [allagents, setAllagents] = useState([]);
    const [allcoaches, setAllcoaches] = useState([]);
    const [allclubs, setAllclubs] = useState([]);
    const [alloffers, setAlloffers] = useState([]);
    const [allevents, setAllevents] = useState([]);
    const [allmessages, setAllmessages] = useState([]);
    const [filtermessages, setFiltermessages] = useState([]);
    const [fullmessages, setFullmessages] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [player, setPlayer] = useState([]);
    const [agent, setAgent] = useState([]);
    const [coach, setCoach] = useState([]);
    const [club, setClub] = useState([]);
    const [msgs, setMsgs] = useState([]);
    const [offerMsgs, setOffermsgs] = useState([]);
    const [visited, setVisited] = useState('');
    const [lastreg, setLastreg] = useState(null);

    //STATES
    const [reloader, setReloader] = useState(1);
    const [checkMsgs, setCheckmsgs] = useState(false);
    
    //LOGIN WITH GOOGLE
    async function logGoogle() {
        const GoogleProvider = new GoogleAuthProvider();
        try {
            const user = await signInWithPopup(auth, GoogleProvider);
            //console.log(user)
        } catch (error) {
            //console.log(error)
        }
    }
    
    //REGISTER USER - REGISTER.JS
    async function registerUser(mail, pass, prof){
        try{
            var x = new Date();
            const user = await createUserWithEmailAndPassword(auth, mail, pass);
            if(prof === "Player"){
                await setDoc(doc(refplayer, auth.currentUser.uid), {
                    key_id: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    name: auth.currentUser.email,
                    lastname: '',
                    profile: prof,
                    verificated: '',
                    mod_type: '1',
                    mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
                    ref: 'refplayer'
                });
                sendEmailVerification(auth.currentUser).then(() => alert("Verification mail sended"));
                navigate('/');
            }else if(prof === "Agent"){
                await setDoc(doc(refagent, auth.currentUser.uid), {
                    key_id: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    name: auth.currentUser.email,
                    lastname: '',
                    profile: prof,
                    verificated: '',
                    verificated_fifa: '',
                    verificated_six: '',
                    mod_type: '1',
                    mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
                    ref: 'refagent'   
                });
                sendEmailVerification(auth.currentUser).then(() => alert("Verification mail sended"));
            }else if(prof === "Coach"){
                await setDoc(doc(refcoach, auth.currentUser.uid), {
                    key_id: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    name: auth.currentUser.email,
                    lastname: '',
                    profile: prof,
                    verificated: '',
                    mod_type: '1',
                    mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
                    ref: 'refcoach'
                });
                sendEmailVerification(auth.currentUser).then(() => alert("Verification mail sended"));
            }
            else if(prof === "Club"){
                await setDoc(doc(refclub, auth.currentUser.uid), {
                    key_id: auth.currentUser.uid,
                    email: auth.currentUser.email,
                    name: auth.currentUser.email,
                    profile: prof,
                    verificated: '',
                    mod_type: '1',
                    mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
                    ref: 'refclub'
                });
                sendEmailVerification(auth.currentUser).then(() => alert("Verification mail sended"));
            }
        }catch(error){
            //console.log(error);
            alert('Capture all the required data.' + '\n' + error)
        }
    }

    //REGISTER USER AND VALIDATE - REGISTER.JS
    /*async function registerUser(mail, pass, prof){
        try{
            var x = new Date();
            const user = await createUserWithEmailAndPassword(auth, mail, pass);
            sendEmailVerification(auth.currentUser).then(() => alert("Verification mail sended"));

        }catch(error){
            //console.log(error);
            alert('Capture all the required data.' + '\n' + error)
        }
    }*/

    var allow_mails = [
        'player@gmail.com',
        'agent@gmail.com',
        'dt.hugosayago@gmail.com',
        'christiannjoudymoun1@gmail.com',
        'coach@gmail.com',
        'club@gmail.com',
        'christiannjoudymoun1@gmail.com',
        'castanochristian46@gmail.com',
        'mannahromeo091@gmail.com',
        'durancamilo957@gmail.com',
        'carlosmansillaxx@gmail.com',
        'directortecnicojuan2021@gmail.com',
        'marcorossa23@gmail.com',
        'asprillapalaciosdeison@gmail.com',
        'luisalberto022017@gmail.com',
        'corzo11d@gmail.com',
        'mssosa1988@gmail.com',
        'thiagomatwew@gmail.com',
        'jatodatorre3@gmail.com',
        'dlarreadt@gmail.com',
        'angelitofer666@gmail.com',
        'adrian.barral26@gmail.com',
        'mzamoralozano@gmail.com',
        'bustap2002@gmail.com',
        'daniellarrea.comdt@gmail.com',
        'neto7137@gmail.com',
        'mariofernandosantacruzceron5@gmail.com',
        'elvinflores1971@gmail.com',
        'pelado292016@gmail.com',
        'gaperezcorrales@gmail.com',
        'kevilino10@gmail.com',
        'conexsportfutbol@gmail.com',
        'nancy@gmail.com'
    ]

    //SIGN IN USER - LOGIN.JS
    async function signIn(mail, pass){
        try{
            const user = await signInWithEmailAndPassword(auth, mail, pass);
            if(user.user.emailVerified == true || allow_mails.includes(user.user.email)){
                //console.log("registro");
                //console.log(user.user.emailVerified);
                navigate('/Wall');
                window.location.reload();
            }else{
                alert("Verify your email to validate your account");
                console.log(user.user.emailVerified);
            }
            
        }catch(error){
            //console.log(error);
            alert("Wrong email or password.")
        }
    }

     //SIGN IN USER - LOGIN.JS
     async function signInT(mail, pass){
        try{
            const user = await signInWithEmailAndPassword(auth, mail, pass);
            if(user){
                //console.log("registro");
                navigate('/Wall');
                window.location.reload();
            }else{
                alert("Verify your email to validate your account");
            }
            
        }catch(error){
            //console.log(error);
            alert("Wrong email or password.")
        }
    }

    //RESET PASSWORD
    async function resetPass(mail){
        try {
            await sendPasswordResetEmail(auth, mail);
            alert("Check your email");
            navigate('/');
        } catch (error) {
            //console.log(error);
        }
    }

    //LOGOUT USER
    function logOut(){
        signOut(auth).then(() => {
        //console.log("Sesión terminada")
        }).catch((error) => {
        //console.log(error);
        });
    }

    //SAVE PLAYER TO DATABASE - PROFILE.JS
    async function savePlayer(fields) {
        await updateDoc(doc(refplayer, auth.currentUser.uid), fields);
        navigate('/User');
        setReloader(reloader + 1);
        //console.log(reloader);
    }

    //SAVE AGENT TO DATABASE - PROFILE.JS
    async function saveAgent(fields) {
        await updateDoc(doc(refagent, auth.currentUser.uid), fields);
        navigate('/User');
        setReloader(reloader + 1);
        //console.log(reloader);
    }

    //SAVE COACH TO DATABASE - PROFILE.JS
    async function saveCoach(fields) {
        await updateDoc(doc(refcoach, auth.currentUser.uid), fields);
        navigate('/User');
        setReloader(reloader + 1);
        //console.log(reloader);
    }

    //SAVE CLUB TO DATABASE - PROFILE.JS
    async function saveClub(fields) {
        await updateDoc(doc(refclub, auth.currentUser.uid), fields);
        navigate('/User');
        setReloader(reloader + 1);
        //console.log(reloader);
    }

    //GET ALL PLAYERS - PLAYERS.JS
    const getPlayers = async() => {
        const q = query(collection(db, "players"), orderBy('mod_update', 'desc'), limit(10));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllplayers(data2);
        setLastreg(data2[data2.length - 1]);
    }

     //GET ALL AGENTS - AGENTS.JS
     const getAgents = async() => {
        const q = query(collection(db, "agents"), orderBy('mod_update', 'desc'), limit(10));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllagents(data2);
        setLastreg(data2[data2.length - 1]);
    }

    //GET ALL COACHES - COACHES.JS
    const getCoaches = async() => {
        const q = query(collection(db, "coaches"), orderBy('mod_update', 'desc'), limit(10));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllcoaches(data2);
        setLastreg(data2[data2.length - 1]);
    }

    //GET ALL CLUBS - CLUBS.JS
    const getClubs = async() => {
        const q = query(collection(db, "clubs"), orderBy('mod_update', 'desc'), limit(10));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllclubs(data2);
        setLastreg(data2[data2.length - 1]);
    }

    //GET AGENT PLAYERS - AGENT.JS
    const getAgentPlayers = async(a, b) => {
        const q = query(collection(db, "players"), where('agent', '==', a),  where('validated_agent', '==', b));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAgplayers(data2);
        //console.log(allplayers);
    }

    //GET FOLLOWING PLAYERS - USER.JS
    const getFollowingPlayers = async(a) => {
        if(activeuser.following){
            const q = query(collection(db, "players"), where('follower', 'array-contains', a));
            const data = await getDocs(q);
            //console.log(data.docs);
            const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
            });
            setFlplayers(data2);
            //console.log(flplayers);
        }else{
            //console.log("No Following");
        } 
    }

    //GET FOLLOWER AGENTS - USER.JS
    const getFollowerAgents = async(a) => {
        if(activeuser.follower){
            const q = query(collection(db, "agents"), where('following', 'array-contains', a));
            const data = await getDocs(q);
            //console.log(data.docs);
            const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
            });
            setFlagents(data2);
            //console.log(flagents);
        }else{
            //console.log("No Followers");
        }
       
    }

    //GET FOLLOWER COACHES - USER.JS
    const getFollowerCoaches = async(a) => {
        if(activeuser.follower){
            const q = query(collection(db, "coaches"), where('following', 'array-contains', a));
            const data = await getDocs(q);
            //console.log(data.docs);
            const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
            });
            setFlcoaches(data2);
            //console.log(flcoaches);
        }else{
            //console.log("No Followers");
        }    
    }

    //GET FOLLOWER CLUBS - USER.JS
    const getFollowerClubs = async(a) => {
        if(activeuser.follower){
            const q = query(collection(db, "clubs"), where('following', 'array-contains', a));
            const data = await getDocs(q);
            //console.log(data.docs);
            const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
            });
            setFlclubs(data2);
            //console.log(flclubs);
        }else{
            //console.log("No Followers");
        }
    }

    //ADVANCED FILTER SEARCH PLAYERS - PLAYERS.JS
    const filterList = async(a, b, c, d, e, f, g, h, i, x) => {
        const q = query(collection(db, x),
         where('gender', '==', a), 
         where('position', '==', b), 
         where('status', '==', c),
         where('verificated', '==', d),
         where('nationality', '==', e));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });

        var z = data2.filter(x => {
            return (getAge(x.birthday) >= f && getAge(x.birthday) <= g) && (x.height >= h && x.height <= i);
        });
        setAllplayers(z);
    }

    //GET AGE
    function getAge(birthDateString){
        const today = new Date();
        const birthDate = new Date(birthDateString);
    
        const yearsDifference = today.getFullYear() - birthDate.getFullYear();
    
        const isBeforeBirthday = today.getMonth() < birthDate.getMonth() || (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());
    
        return isBeforeBirthday ? yearsDifference - 1 : yearsDifference;
    };

    //SEARCH PLAYERS - PLAYERS.JS
    const searchListPlayers = async(a, b) => {
        const q = query(collection(db, b), where('tags', 'array-contains-any', a));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllplayers(data2);
    }

    //SEARCH AGENTS - AGENTS.JS
    const searchListAgents = async(a, b) => {
        const q = query(collection(db, b), where('tags', 'array-contains-any', a));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllagents(data2);
    }

    //SEARCH CLUBS - CLUBS.JS
    const searchListClubs = async(a, b) => {
        const q = query(collection(db, b), where('tags', 'array-contains-any', a));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllclubs(data2);
    }

    //SEARCH COACHES - COACHES.JS
    const searchListCoaches = async(a, b) => {
        const q = query(collection(db, b), where('tags', 'array-contains-any', a));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllcoaches(data2);
    }


    //NEXT PLAYERS - PLAYERS.JS
    const nextPlayers = async(a) => {
        const q = query(collection(db, a), orderBy('mod_update', 'desc'), limit(10), startAfter(lastreg.mod_update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setAllplayers(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    };

    //NEXT AGENTS - AGENTS.JS
    const nextAgents = async(a) => {
        const q = query(collection(db, a), orderBy('mod_update', 'desc'), limit(10), startAfter(lastreg.mod_update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setAllagents(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    };

    //NEXT CLUBS - CLUBS.JS
    const nextClubs = async(a) => {
        const q = query(collection(db, a), orderBy('mod_update', 'desc'), limit(10), startAfter(lastreg.mod_update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setAllclubs(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    };

    //NEXT COACHES - COACHES.JS
    const nextCoaches = async(a) => {
        //console.log('testing');
        const q = query(collection(db, a), orderBy('mod_update', 'desc'), limit(10), startAfter(lastreg.mod_update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setAllcoaches(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    };


    //LOAD WALL TIMELINE - WALL.JS
    const getWall = async() => {
        const q1 = query(collection(db, "players"), orderBy('mod_update', 'desc'), limit(20));
        const q2 = query(collection(db, "agents"), orderBy('mod_update', 'desc'), limit(20));
        const q3 = query(collection(db, "coaches"), orderBy('mod_update', 'desc'), limit(20));
        const q4 = query(collection(db, "clubs"), orderBy('mod_update', 'desc'), limit(20));
        
        const data1 =  await getDocs(q1);
        const data2 =  await getDocs(q2);
        const data3 =  await getDocs(q3);
        const data4 =  await getDocs(q4);
        
        
        const dat1 = data1.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const dat2 = data2.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const dat3 = data3.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const dat4 = data4.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        
        const mix = dat1.concat(dat2, dat3, dat4);
        const sortedmix = mix.sort((a, b) => {
            const dateA = new Date(a.mod_update);
            const dateB = new Date(b.mod_update);
            return dateB - dateA; // Orden descendente (del más nuevo al más antiguo)
        });
        
        setWallplayers(sortedmix);
        //console.log(data2);
    };


    //GET PLAYER AGENT - PLAYER.JS & USER.JS
    const getPlayerAgent = async(a) => {
        if(activeuser.agent){
            const q = query(collection(db, "agents"), where('key_id', '==', a));
            const data = await getDocs(q);
            const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
            });
            setPlagent(data2);
            //console.log(playeragent);
        }else{
            //console.log("No agent")
        }
        
    }

    
    //GET ALL OPEN OFFERS 
    const getOffers = async() => {
        const q = query(collection(db, "offers"), where( 'receptor', '==', 'open'), orderBy('update', 'desc'), limit(10));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAlloffers(data2);
        setLastreg(data2[data2.length - 1]);
    }

    //NEXT OFFERS - OFFERS.JS
    const nextOffers= async(a) => {
        const q = query(collection(db, a), where( 'receptor', '==', 'open'), orderBy('update', 'desc'), limit(10), startAfter(lastreg.update));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setAlloffers(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    };

    //SEARCH EVENTS - EVENTS.JS
    const searchListOffers = async(a, b) => {
        const q = query(collection(db, b), where( 'receptor', '==', 'open'), where('tags', 'array-contains-any', a));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAlloffers(data2);
    }

     //GET ALL PLAYER OFFERS 
     const getOffersPlayer = async() => {
        const q = query(collection(db, "offers"), where( 'receptor', '==', activeuser.key_id));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAlloffers(data2);
        //console.log(alloffers);
    }

    //GET ALL PLAYER OFFERS 
    const getOffersAgent = async() => {
        const q = query(collection(db, "offers"), where( 'creator', '==', activeuser.key_id));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAlloffers(data2);
        //console.log(alloffers);
    }

     //GET PLAYER - PLAYER.JS
     async function getPlayer(a){
        const file = doc(db, "players", a);
        const dat = await getDoc(file);
        //console.log(dat.data()); 
        setPlayer(dat.data());
    }

    //GET AGENT - AGENT.JS
    async function getAgent(a){
        const file = doc(db, "agents", a);
        const dat = await getDoc(file);
        //console.log(dat.data()); 
        setAgent(dat.data());
    }

    //GET COACH - COACH.JS
    async function getCoach(a){
        const file = doc(db, "coaches", a);
        const dat = await getDoc(file);
        //console.log(dat.data()); 
        setCoach(dat.data());
    }

    //GET CLUB - CLUB.JS
    async function getClub(a){
        const file = doc(db, "clubs", a);
        const dat = await getDoc(file);
        //console.log(dat.data()); 
        setClub(dat.data());
    }

    //GET CURRENT USER - USER.JS & PLAYER.JS
    async function getUser(){
        
        if(auth.currentUser != null){
            const filePlayer = doc(db, "players", auth.currentUser.uid);
            const fileAgent = doc(db, "agents", auth.currentUser.uid);
            const fileCoach = doc(db, "coaches", auth.currentUser.uid);
            const fileClub = doc(db, "clubs", auth.currentUser.uid);

            const dat1 = await getDoc(filePlayer);
            const dat2 = await getDoc(fileAgent);
            const dat3 = await getDoc(fileCoach);
            const dat4 = await getDoc(fileClub);
        
            if(dat1.data() != undefined){ 
                setActiveuser(dat1.data());
            }
            else if(dat2.data() != undefined){
                setActiveuser(dat2.data());
            }
            else if(dat3.data() != undefined){
                setActiveuser(dat3.data());
            }
            else if(dat4.data() != undefined){
                setActiveuser(dat4.data());
            }
           
        }else{
            //console.log("No hay sesión activa")
        } 
    }

    //USE EFFECT
    useEffect(() => {
        setTimeout(() => {
            if(auth.currentUser == null){
                //console.log("No hay sesión activa");
            }else{
                getUser();
                //console.log(activeuser);
                checkPay();
            }
          }, 1000);
    },[reloader]);

    /*USE EFFECT
    useEffect(() => {
        setTimeout(() => {
            if(auth.currentUser == null){
                //console.log("No hay sesión activa");
            }else{
                getMessages();
            }
          }, 1000);
    },[checkMsgs])*/

    
    //GET ALL EVENTS 
    const getEvents = async() => {
        const q = query(collection(db, "events"), orderBy('update', 'desc'), limit(10));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllevents(data2);
        setLastreg(data2[data2.length - 1]);
    }

    //NEXT EVENTS - EVENTS.JS
    const nextEvents = async(a) => {
        const q = query(collection(db, a), orderBy('update', 'desc'), startAfter(lastreg.update), limit(10));
        const data =  await getDocs(q);
        const data2 = data.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });
        setAllevents(prevData => prevData.concat(data2));
        setLastreg(data2[data2.length - 1]);
    };

    //SEARCH EVENTS - EVENTS.JS
    const searchListEvents = async(a, b) => {
        const q = query(collection(db, b), where('tags', 'array-contains-any', a));
        const data = await getDocs(q);
        //console.log(data.docs);
        const data2 = data.docs.map(doc =>{
        return {
            id: doc.id,
            ...doc.data()
        }
        });
        setAllevents(data2);
    }

    //CHECK PAY
    async function checkPay(){
        /*try {
            //const result = await axios.get('http://localhost:3001/client?email=' + auth.currentUser.email);
            const result = await axios.get('https://conexserver.fly.dev/client?email=' + auth.currentUser.email);
            const customerId = Object.values(result)[0].id;
            setPremium(customerId);

            //const subscriptionResult = await axios.get('http://localhost:3001/subs?customerId=' + customerId);
            const subscriptionResult = await axios.get('https://conexserver.fly.dev/subs?customerId=' + customerId);
            setPremiumsub(subscriptionResult.data.status);
        } catch (error) {
            console.error(error);
        }*/
        console.log("Not connected to Stripe")
    }

    //BUY MEMBERSHIP
    async function buySub(){
        /*const x = await axios.post("https://conexserver.fly.dev/checkout", {
        email: activeuser.email 
        })
        window.location.href = x.data.url*/
        
        /*const x = await axios.post("http://localhost:3001/checkout", {
        email: activeuser.email 
        })
        window.location.href = x.data.url*/
        alert("This feature is not enabled yet.")
    }

    
    //LOAD ALL OFFERS FOR PREMIUM - USER.JS
    function loadOffers(){
        setProposals(activeuser.offers);
        //console.log(proposals);
    }

    //FILTER OFFERS FOR FREE - USER.JS
    function loadOffersmin(){
        const p = activeuser.offers.slice(0, 2).map((it, index) =>{
            return {
                id: index,
                ...it
            }
        });
        //console.log(p);
    }

    //SAVE OFFER IN PLAYER PROFILE - PLAYER.JS
    async function addOffer(a, b){
        await updateDoc(doc(refplayer, a), {
        offers: arrayUnion(b)
        });
    }

    //SAVE OFFER IN AGENT PROFILE - PLAYER.JS
    async function setOffer(a, b){
        await updateDoc(doc(refagent, a), {
        offers: arrayUnion(b)
        });
    }

    //SAVE EVENT IN AGENT PROFILE - PLAYER.JS
    async function setEvent(a, b){
        await updateDoc(doc(refagent, a), {
        events: arrayUnion(b)
        });
    }

    //SAVE OFFER
    async function createOffer(a, b){
        await setDoc(doc(refoffers, a), b);
        navigate('/User');
    }

    //DELETE OFFER
    async function quitOffer(a){
        await deleteDoc(doc(refoffers, a));
        setReloader(reloader + 1);
    }

    //SAVE EVENT
    async function createEvent(a, b){
        await setDoc(doc(refevents, a), b);
        navigate('/User');
    }

    //SAVE VIDEOS IN PLAYER PROFILE - USER.JS
    async function addVideoplayer(a, b){
        var x = new Date();
        await updateDoc(doc(refplayer, a), {
        videos: arrayUnion(b),
        mod_type: '2',
        mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }

    //SAVE VIDEOS IN AGENT PROFILE - USER.JS
    async function addVideocoach(a, b){
        var x = new Date();
        await updateDoc(doc(refcoach, a), {
        videos: arrayUnion(b),
        mod_type: '2',
        mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }

    //DELETE VIDEOS IN PLAYER PROFILE - USER.JS
    async function quitVideoplayer(a, b){
        await updateDoc(doc(refplayer, a), {
        videos: arrayRemove(b)
        });
        setReloader(reloader + 1);
    }

    //DELETE VIDEOS IN COACH PROFILE - COACH.JS
    async function quitVideocoach(a, b){
        await updateDoc(doc(refcoach, a), {
        videos: arrayRemove(b)
        });
        setReloader(reloader + 1);
    }

    //SAVE TEAM IN PLAYER PROFILE - USER.JS
    async function addTeamPl(a, b){
        var x = new Date();
        await updateDoc(doc(refplayer, a), {
        teams: arrayUnion(b),
        mod_type: '3',
        mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }

    //SAVE TEAM IN AGENT PROFILE - USER.JS
    async function addTeamAg(a, b){
        var x = new Date();
        await updateDoc(doc(refagent, a), {
        teams: arrayUnion(b),
        mod_type: '3',
        mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }

    //SAVE TEAM IN COACH PROFILE - USER.JS
    async function addTeamCo(a, b){
        var x = new Date();
        await updateDoc(doc(refcoach, a), {
        teams: arrayUnion(b),
        mod_type: '3',
        mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }

    //QUIT TEAM IN PLAYER PROFILE - USER.JS
    async function quitTeamPl(a, b){
        await updateDoc(doc(refplayer, a), {
        teams: arrayRemove(b)
        });
        setReloader(reloader + 1);
    }

    //QUIT TEAM IN AGENT PROFILE - USER.JS
    async function quitTeamAg(a, b){
        await updateDoc(doc(refagent, a), {
        teams: arrayRemove(b)
        });
        setReloader(reloader + 1);
    }

    //QUIT TEAM IN COACH PROFILE - USER.JS
    async function quitTeamCo(a, b){
        await updateDoc(doc(refcoach, a), {
        teams: arrayRemove(b)
        });
        setReloader(reloader + 1);
    }

    //SAVE AGENT TO PLAYER - PLAYER.JS
    async function setAg(a, b){
        await updateDoc(doc(refplayer, a), {
        agent: b,
        validated_agent: "0",
        });
        setReloader(reloader + 1);
    }

    //ACCEPT AGENT TO PLAYER - PLAYER.JS
    async function accAg(a){
        var x = new Date();
        await updateDoc(doc(refplayer, a), {
        validated_agent: "1",
        mod_type: '5',
        mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }

    //DELETE AGENT TO PLAYER - PLAYER.JS
    async function delAg(a){
        await updateDoc(doc(refplayer, a), {
        agent: deleteField(),
        validated_agent: deleteField()
        });
        setReloader(reloader + 1);
    }

    //FOLLOW PLAYER
    async function followPl(a, b, c){
        await updateDoc(doc(refplayer, b), {
            follower: arrayUnion(a)
        });
        await updateDoc(doc(eval(c), a), {
            following: arrayUnion(b)
        });
        //setReloader(reloader + 1);
        window.location.reload();
    }


    //GET CHATS ABOUT USER
    const getChats = async(a) => {
        const q1 = query(collection(db, "chat"), where( 'creator', '==', activeuser.key_id));
        const q2 = query(collection(db, "chat"), where( 'receptor', '==', activeuser.key_id));
        
        const data1 = await getDocs(q1);
        const data2 = await getDocs(q2);

        const data3 = data1.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const data4 = data2.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const data5 = (data3.concat(data4))
        const data6 = data5.filter(x => ( (x.receptor === a && x.creator === activeuser.key_id) || (x.receptor === activeuser.key_id && x.creator === a) ));
        
        //console.log(data5);
        //console.log(data6);

        return {data6};
    }

    //CREATE NEW CHAT
    async function createChat(a, b, c, d, e, f, g){
        const chatFound = await getChats(c);
        ////console.log(chatFound.data6[0].id);

        if(chatFound.data6[0]){
            //console.log("El chat existe");
            navigate(`/Chat_contact/${ chatFound.data6[0].id }`);
        }
        else{
            //console.log("No existe chat");
            await setDoc(doc(refchat, a), {
                chatid: a,
                creator: b,
                receptor: c,
                creator_name: d,
                receptor_name: e,
                creator_profile: f,
                receptor_profile: g,
                messages:[]
            });
            navigate(`/Chat_contact/${ a }`);
        } 
    }


    //SAVE MESSAGE IN OFFER
    async function saveMsgOffer(a, b){
        await updateDoc(doc(refoffers, a), {
        messages: arrayUnion(b)
        });
    }

    //SAVE MESSAGE IN CHAT
    async function saveMsgContact(a, b){
        await updateDoc(doc(refchat, a), {
        messages: arrayUnion(b)
        });
    }

    //GET MESSAGES OFFER
    function getMsgOffer(a){
        const x = doc(refoffers, a);
        onSnapshot(x, (doc) => {
            setOffermsgs(doc.data());
        });
    }

    //GET MESSAGES CONTACT
    function getMsgContact(a){
        const x = doc(refchat, a);
        onSnapshot(x, (doc) => {
            setMsgs(doc.data());
        });
    }

    //SAVE MESSAGE VIEW
    async function saveMsgView(a, b){
        await updateDoc(doc(refchat, a), b);
    }

    //GET ALL MESSAGES
    const getMessages = async() => {
        const q1 = query(collection(db, "chat"), where("creator", "==", activeuser.key_id));
        const q2 = query(collection(db, "chat"), where("receptor", "==", activeuser.key_id));
        
        const data1 =  await getDocs(q1);
        const data2 =  await getDocs(q2);
        
        const dat1 = data1.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const dat2 = data2.docs.map(doc =>{
            return {
                id: doc.id,
                ...doc.data()
            }
        });

        const allmsgs = dat1.concat(dat2);

        const resultado = allmsgs.filter(dato => 
            dato.messages && Array.isArray(dato.messages) && dato.messages.some(message => message.view === "no" && message.author != activeuser.key_id)
        );
        setAllmessages(allmsgs);
        setFiltermessages(resultado);
        setCheckmsgs(true);
    }

    
    //UPLOAD PHOTO PROFILE FULL QUALITY
    async function uploadPhoto(a, b){
        const refstorage = ref(storage, a);
        await uploadBytes(refstorage, b);
        alert('Image uploaded successfully');
        getDownloadURL(refstorage).then(url =>{
            if(activeuser.profile == "Player" ){
                updateDoc(doc(refplayer, activeuser.key_id), {
                    photoURL: url
                });
            }
            else if (activeuser.profile == "Agent"){
                updateDoc(doc(refagent, activeuser.key_id), {
                    photoURL: url
                });
            }
        });
    }

    //UPLOAD PHOTO PROFILE COMPRESSES
    async function uploadPhotoMin(a, b){
        const refstorage = ref(storage, a);
        await uploadString(refstorage, b, "data_url");
        getDownloadURL(refstorage).then(url =>{
            if(activeuser.profile == "Player" ){
                updateDoc(doc(refplayer, activeuser.key_id), {
                    photoURL: url
                });
                setReloader(reloader + 1);
            }
            else if (activeuser.profile == "Agent"){
                updateDoc(doc(refagent, activeuser.key_id), {
                    photoURL: url
                });
                setReloader(reloader + 1);
            }
            else if (activeuser.profile == "Coach"){
                updateDoc(doc(refcoach, activeuser.key_id), {
                    photoURL: url
                });
                setReloader(reloader + 1);
            }
            else if (activeuser.profile == "Club"){
                updateDoc(doc(refclub, activeuser.key_id), {
                    photoURL: url
                });
                setReloader(reloader + 1);
            }
        });
    }

    //SET AVAILABILITY
    async function saveStatus(a, b, c){
        var x = new Date();
        await updateDoc(doc(refplayer, a), {
            status: b,
            status_date: c,
            mod_type: '4',
            mod_update: x.getFullYear() + '-' + ('0' + (x.getMonth()+1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2) + ' ' + ('0' + x.getHours()).slice(-2) + ':' + ('0' + x.getMinutes()).slice(-2) + ':' + ('0' + x.getSeconds()).slice(-2) + '.' + ('00' + x.getMilliseconds()).slice(-3),
        });
        setReloader(reloader + 1);
    }


    return(
        <DataContext.Provider 
        value={{
            registerUser,
            signIn,
            resetPass,
            logOut,
            revUser,
            savePlayer,
            saveAgent,
            saveCoach,
            saveClub,
            getUser,
            getPlayers,
            getAgents,
            getCoaches,
            getClubs,
            getOffers,
            alloffers,
            getEvents,
            allevents,
            activeuser,
            loadOffers,
            loadOffersmin,
            allplayers,
            allagents,
            allcoaches,
            allclubs,
            getPlayer,
            player,
            getAgent,
            agent,
            getCoach,
            coach,
            getClub,
            club,
            addOffer,
            setOffer,
            createOffer,
            createEvent,
            setEvent,
            allevents,
            user_email,
            user_id,
            logGoogle,
            reloader,
            setReloader,
            addVideoplayer,
            addVideocoach,
            quitVideoplayer,
            quitVideocoach, 
            addTeamPl,
            addTeamAg,
            addTeamCo,
            quitTeamPl,
            quitTeamAg,
            quitTeamCo,
            setAg,
            accAg,
            delAg, 
            saveMsgOffer,
            saveMsgContact,
            getMsgOffer, 
            getMsgContact,
            msgs,
            createChat,
            followPl,
            uploadPhoto,
            uploadPhotoMin,
            premium,
            premiumsub,
            saveStatus,
            saveMsgView,
            getMessages,
            allmessages,
            filtermessages,
            checkMsgs,
            setVisited,
            visited,
            setOffermsgs,
            offerMsgs,
            buySub,
            quitOffer,
            getChats,
            getWall,
            wallplayers,
            nextPlayers,
            nextAgents,
            nextClubs,
            nextCoaches,
            nextEvents,
            nextOffers,
            searchListPlayers,
            searchListAgents,
            searchListClubs,
            searchListCoaches,
            searchListEvents,
            searchListOffers,
            filterList,
            getAgentPlayers,
            agentplayers,
            getPlayerAgent,
            playeragent,
            getOffersPlayer,
            getOffersAgent,
            getFollowingPlayers,
            flplayers,
            getFollowerAgents,
            flagents,
            getFollowerCoaches,
            flcoaches,
            getFollowerClubs,
            flclubs,
            lastreg
        }}>

            {children}
        
        </DataContext.Provider>
    )
}