import React, {useEffect, useState, useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';
import { nanoid } from 'nanoid';

import Top from './top.js';
import Countries from './countries.json';

import { DataContext } from '../firebase/datacontext.js';

const Event = () => {
  
  //
  const { activeuser, createEvent } = useContext(DataContext);

  //TEXTS IDIOMS
  var txt1 = "Create event";
  var txt2 = "EVENT DESCRIPTION:";
  var txt3 = "COUNTRY:";
  var txt4 = "Select country";
  var txt5 = "ADDRESS:";
  var txt6 = "CREATE";
  var txt7 = "CANCEL";
  var txt8 = "Event created.";
  var txt9 = "Max. 100 characters";
  var txt10 = "Max. 200 characters";

  function changeLang(){
      var x = localStorage.getItem('idiom');

      if( x == "esp"){
          //console.log("Lang: Español");
          txt1 = "Crear evento";
          txt2 = "DESCRIPCIÓN DEL EVENTO:";
          txt3 = "PAÍS:";
          txt4 = "Seleccionar país";
          txt5 = "DIRECCIÓN:";
          txt6 = "CREAR";
          txt7 = "CANCELAR";
          txt8 = "Evento creado.";
          txt9 = "Max. 100 caracteres";
          txt10 = "Max. 200 caracteres";
      }
      else{
          //console.log("Lang: English");
      }
  }

  changeLang();


  //USE EFFECT
  useEffect(() => {
    
  },[]);

  //
  const navigate = useNavigate();

  function cancelEdit(){
    navigate('/User')
  }

  //
  var d = new Date();
  const formik = useFormik ({
    initialValues:{
        event: '',
        event_id: nanoid(12),
        nationality:'',
        address:'',
        update: d.getFullYear() + '-' + ('0' + (d.getMonth()+1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2) + '.' + ('00' + d.getMilliseconds()).slice(-3),
    },
    onSubmit: reg =>{
      reg.tags = (reg.event + ' ' + reg.nationality).split(' ').map(word => word.toLowerCase());
      reg.creator_name = activeuser.name + ' ' + activeuser.lastname;
      reg.creator = activeuser.key_id;
      reg.creator_profile = activeuser.profile;
      //console.log(reg);
      createEvent(reg.event_id, reg);
      alert(txt8);
    }
  })


  return (
    <>
    <Top/>
    <div className="Main">

      <div className="log_box">
        <h1>{txt1}</h1>
        <form onSubmit={formik.handleSubmit} className="fields">
          
          <div className="field">
            <label htmlFor="bio">{txt2}</label>
            <textarea
              id="event"
              name="event"
              maxLength="200"
              value={formik.values.event}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt9}
            >
            </textarea>
          </div>
          <div className="field">
            <label htmlFor="nationality">{txt3}</label>
            <select 
              id="nationality"
              name="nationality"
              value={formik.values.nationality}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
                {
                  Countries.map((item, index) => (
                      <option value={item.en}>{item.en}</option>
                  ))
                }
            </select>
          </div>
          <div className="field">
            <label htmlFor="bio">{txt5}</label>
            <textarea
              id="address"
              name="address"
              maxLength="200"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={txt10}
            >
            </textarea>
          </div>  
          <button type="submit" className="btn">{txt6}</button>
        </form>
        <button className="btn_cancel" onClick={cancelEdit}>{txt7}</button>
     </div>
    </div>
    </>

  );
}

export default Event;